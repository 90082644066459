import React from "react";
import { connect } from "react-redux";
import "../../App.css";
import Timeout from "./Timeout";
import { config } from "../../tools/config";

const Header = ({ apiVersion, auth }) => {
  return (
    <>
      <header className="App-header">
        <div>
          <span
            title={`App Version: ${config.env.REACT_APP_VERSION}\nAPI Version: ${apiVersion}`}
          >
            Mariner Time & Attendance
            {config.env.REACT_APP_CUSTOM_NODE_ENV &&
              ` (${config.env.REACT_APP_CUSTOM_NODE_ENV})`}
          </span>
          <Timeout minutesToNotice={10} minutesToClose={15} auth={auth} />
        </div>
      </header>
    </>
  );
};

// Redux
function mapStateToProps(state) {
  return { apiVersion: state.apiStatus.version };
}
export default connect(mapStateToProps)(Header);
