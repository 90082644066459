import { user as actionTypes } from "./actionTypes";
import * as userApi from "../../api/userApi";

// Get the currently logged in user
export function loadUser() {
  return async function (dispatch) {
    return userApi
      .getUser()
      .then(user => {
        // console.log("getUser complete", user);
        if (user) dispatch({ type: actionTypes.LOAD_USER_SUCCESS, user });
        else dispatch({ type: actionTypes.LOAD_USER_FAILURE });
      })
      .catch(error => dispatch({ type: actionTypes.LOAD_USER_FAILURE, error }));
  };
}

// Get all users for User Management
export function loadUsers() {
  return async function (dispatch) {
    return userApi.getUsers().then(users => {
      if (users)
        return dispatch({ type: actionTypes.LOAD_USERS_SUCCESS, users });
      else return dispatch({ type: actionTypes.LOAD_USERS_FAILURE });
    });
  };
}

export function addUser(user) {
  return async dispatch => {
    dispatch({ type: actionTypes.ADD_USER_START, user });
    return userApi
      .addUser(user)
      .then(newUser => {
        dispatch({ type: actionTypes.ADD_USER_SUCCESS, user: newUser });
        return newUser;
      })
      .catch(error => dispatch({ type: actionTypes.ADD_USER_FAILURE, error }));
  };
}

export function updateUser(user) {
  return async dispatch => {
    dispatch({ type: actionTypes.UPDATE_USER_START, user });
    return userApi
      .updateUser(user)
      .then(() => {
        dispatch({ type: actionTypes.UPDATE_USER_SUCCESS, user });
      })
      .catch(error => {
        dispatch({ type: actionTypes.UPDATE_USER_FAILURE, error });
        throw error;
      });
  };
}

// export function setUserPermissions(permissions) {
//   return async function(dispatch) {
//     dispatch({ type: actionTypes.SET_USER_PERMISSIONS_START, permissions });
//   };
// }

// export function setUserDivisions(divisions) {
//   return async function(dispatch) {
//     dispatch({ type: actionTypes.SET_USER_DIVISIONS_START, divisions });
//   };
// }

/// Set user name and role for testing purposes
export function setUserRole(role) {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SET_USER_ROLE_START, role });
  };
}

export function setUserName(name) {
  return async function (dispatch) {
    dispatch({ type: actionTypes.SET_USER_NAME_START, name });
  };
}
