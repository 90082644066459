import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "./Modal";
import { resetTimeout } from "../../redux/actions/timeoutActions";
import {
  clearCurrentEdit,
  saveAndClearCurrentEdit,
  setHasChanges
} from "../../redux/actions/currentEditActions";

const Timeout = ({
  timeout,
  resetTimeout,
  user,
  hasChanges,
  clearCurrentEdit,
  saveAndClearCurrentEdit,
  setHasChanges,
  ...props
}) => {
  const [inactiveTime, setInactiveTime] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const minutesToNotice =
    (props.minutesToNotice && Number(props.minutesToNotice)) || 10;
  const minutesToClose =
    (props.minutesToClose && Number(props.minutesToClose)) || 15;

  useEffect(() => {
    const logoutPath = "/timeout";

    const checkForTimeout = () => {
      const timeDiff = Date.now() - timeout.lastActive;
      setInactiveTime(timeDiff);

      // Show warning dialog after minutesToNotice
      if (!isDialogOpen && timeDiff > minutesToNotice * 60000) {
        setIsDialogOpen(true);
      }

      // End session after minutesToClose
      if (isDialogOpen && timeDiff > minutesToClose * 60000) {
        saveAndClearCurrentEdit().then(() => {
          props.auth.logout();
          props.history.push(logoutPath);
        });
      }
    };

    if (props.history.location.pathname !== logoutPath) {
      const timer = setInterval(checkForTimeout, 5000);
      return () => clearInterval(timer);
    }
  }, [
    props.history,
    isDialogOpen,
    timeout.lastActive,
    minutesToNotice,
    minutesToClose,
    setHasChanges,
    saveAndClearCurrentEdit,
    user,
    props.auth
  ]);

  // Handle attempts to navigate away or close the window/tab.
  useEffect(() => {
    // Use react-router history.block() to catch navigation within the app
    const message = `There are unsaved changes.
      Are you sure you want to leave and lose your changes?`;
    const unblock = props.history.block(() => {
      if (hasChanges) {
        console.log("block, hasChanges, no clear");
        return message;
      } else {
        console.log("block, no changes, clear");
        clearCurrentEdit(user.id);
      }
    });

    // Use beforeunload event to handle naviagation outside the app
    const unloadHandler = e => {
      console.log("unloading...");
      e.preventDefault();
      e.returnValue = "Do you want to leave?";
      clearCurrentEdit(user.id); // This runs even if I cancel the navigation.
    };
    window.addEventListener("beforeunload", unloadHandler);

    // return function runs when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", unloadHandler);
      unblock();
    };
  }, [hasChanges, props.history, clearCurrentEdit, user.id]);

  const closeModal = () => {
    setIsDialogOpen(false);
  };
  const refresh = () => {
    // reset last active time
    resetTimeout();
    closeModal();
  };

  return (
    <>
      {props.location.search.includes("showInactiveTime") && (
        <> - Seconds since activity: {Math.floor(inactiveTime / 1000)}</>
      )}
      <Modal isOpen={isDialogOpen} modalClass="timeout" hideHeader={true}>
        <>
          <h1>Are You Still There?</h1>
          <p>
            Your session has been inactive for{" "}
            {Math.floor(inactiveTime / 60000)} minutes.
          </p>
          <p>
            After {minutesToClose} minutes, your session will be closed
            following an attempt to save any unsaved changes. To keep your
            session active, please click the button below.
          </p>
          <button onClick={refresh}>Refresh Session</button>
          <p>&nbsp;</p>
        </>
      </Modal>
    </>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    timeout: state.timeout,
    user: state.user,
    hasChanges: state.currentEdit && state.currentEdit.hasChanges ? true : false
  };
}
const mapDispatchToProps = {
  resetTimeout,
  clearCurrentEdit,
  saveAndClearCurrentEdit,
  setHasChanges
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Timeout)
);
