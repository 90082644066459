import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formatDateTime } from "../../utils/utils";
import { handleError } from "../../api/apiUtils";
import { hasPerm, perm, role } from "../../utils/userUtils";
import {
  loadComments,
  updateNewComment,
  addComment
} from "../../redux/actions/currentEditActions";
import { toast } from "react-toastify";

const TimesheetComments = ({
  tsid,
  canEdit,
  user,
  comments,
  loadComments,
  updateNewComment,
  newComment,
  ...props
}) => {
  useEffect(() => {
    loadComments(tsid);
  }, [tsid, loadComments]);
  // event handlers
  const handleCommentChange = e => {
    updateNewComment(e.target.value);
  };

  const handleAddComment = e => {
    e.preventDefault();
    if (newComment && newComment.trim()) {
      const comment = {
        timesheetId: tsid,
        commentById: user.id,
        commentByName: user.fullName,
        commentOn: new Date().toJSON(),
        comment: newComment.trim(),
        commentByRole: user.roleCode
      };
      props
        .addComment(comment)
        .then(() => toast.success("Comment saved"))
        .catch(handleError);
      updateNewComment("");
    }
  };

  const filteredComments = () => {
    // console.log(comments);
    return comments
      .filter(
        c =>
          hasPerm(user, perm.VAC) ||
          (c.commentByRole === role.TK && hasPerm(user, perm.VCTK)) ||
          (c.commentByRole === role.PP && hasPerm(user, perm.VCPP)) ||
          (c.commentByRole === role.PA && hasPerm(user, perm.VCPA))
      )
      .sort((a, b) => new Date(b.commentOn) - new Date(a.commentOn));
  };

  return (
    <div>
      <div>
        <span className="field-label">Comments</span>
        <span className="">
          {newComment && newComment.length > 0
            ? ` (${255 - newComment.length} characters remaining)`
            : ""}
        </span>
      </div>
      <div>
        {canEdit && (
          <>
            <input
              type="text"
              maxLength={255}
              className="comment"
              value={newComment}
              onChange={handleCommentChange}
            />
            <button onClick={handleAddComment}>Add Comment</button>
          </>
        )}
      </div>
      <div className="comment-container">
        {filteredComments().map((c, i) => (
          <div
            key={i}
            className={"comment-block" + (i % 2 === 0 ? " alternate-bkg" : "")}
          >
            <div className="comment-icon"></div>
            <div className="comment-details">
              <div className="field-label">{c.commentByName}</div>
              <div>{formatDateTime(c.commentOn)}</div>
              <div>{c.comment}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    user: state.user,
    comments: state.currentEdit.comments || [],
    newComment: state.currentEdit ? state.currentEdit.newComment || "" : ""
  };
}
const mapDispatchToProps = { loadComments, updateNewComment, addComment };

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetComments);
