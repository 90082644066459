export const FORMAT_FIELD = "FORMAT_FIELD";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const REMOVE_CARD = "REMOVE_CARD";

export const payPeriod = {
  GET_PAYPERIODS_START: "GET_PAYPERIODS_START",
  GET_PAYPERIODS_SUCCESS: "GET_PAYPERIODS_SUCCESS",
  OPEN_PAYPERIOD_START: "OPEN_PAYPERIOD_START",
  OPEN_PAYPERIOD_SUCCESS: "OPEN_PAYPERIOD_SUCCESS",
  OPEN_PAYPERIOD_FAILURE: "OPEN_PAYPERIOD_FAILURE",
  CLOSE_PAYPERIOD_START: "CLOSE_PAYPERIOD_START",
  CLOSE_PAYPERIOD_SUCCESS: "CLOSE_PAYPERIOD_SUCCESS",
  CLOSE_PAYPERIOD_FAILURE: "CLOSE_PAYPERIOD_FAILURE",
  PROCESS_PAYPERIOD_START: "PROCESS_PAYPERIOD_START",
  PROCESS_PAYPERIOD_SUCCESS: "PROCESS_PAYPERIOD_SUCCESS",
  PROCESS_PAYPERIOD_FAILURE: "PROCESS_PAYPERIOD_FAILURE",
  EXPORT_PAYPERIOD_START: "EXPORT_PAYPERIOD_START",
  EXPORT_PAYPERIOD_SUCCESS: "EXPORT_PAYPERIOD_SUCCESS",
  EXPORT_PAYPERIOD_FAILURE: "EXPORT_PAYPERIOD_FAILURE",
  CREATE_EXPORT_PAYPERIOD_START: "CREATE_EXPORT_PAYPERIOD_START",
  CREATE_EXPORT_PAYPERIOD_SUCCESS: "CREATE_EXPORT_PAYPERIOD_SUCCESS",
  CREATE_EXPORT_PAYPERIOD_FAILURE: "CREATE_EXPORT_PAYPERIOD_FAILURE",
  INVALIDATE_PAYPERIODS: "INVALIDATE_PAYPERIODS"
};

export const timesheet = {
  LOAD_TIMESHEETS_START: "LOAD_TIMESHEETS_START",
  LOAD_TIMESHEETS_SUCCESS: "LOAD_TIMESHEETS_SUCCESS",
  LOAD_TIMESHEETS_FAILURE: "LOAD_TIMESHEETS_FAILURE",
  LOAD_TIMESHEET_START: "LOAD_TIMESHEET_START",
  LOAD_TIMESHEET_SUCCESS: "LOAD_TIMESHEET_SUCCESS",
  ADD_TIMESHEET_SUCCESS: "ADD_TIMESHEET_SUCCESS",
  UPDATE_TIMESHEET_START: "UPDATE_TIMESHEET_START",
  UPDATE_TIMESHEET_SUCCESS: "UPDATE_TIMESHEET_SUCCESS",
  UPDATE_TIMESHEET_STATUS_START: "UPDATE_TIMESHEET_STATUS_START",
  UPDATE_TIMESHEET_STATUS_SUCCESS: "UPDATE_TIMESHEET_STATUS_SUCCESS",
  UPDATE_TIMESHEET_HOURS_START: "UPDATE_TIMESHEET_HOURS_START",
  UPDATE_TIMESHEET_HOURS_SUCCESS: "UPDATE_TIMESHEET_HOURS_SUCCESS",
  INVALIDATE_TIMESHEETS: "INVALIDATE_TIMESHEETS"
};

export const lookups = {
  LOAD_EARNINGTYPES_SUCCESS: "LOAD_EARNINGTYPES_SUCCESS",
  LOAD_HOURSTYPES_SUCCESS: "LOAD_HOURSTYPES_SUCCESS",
  LOAD_POSITIONS_SUCCESS: "LOAD_POSITIONS_SUCCESS",
  LOAD_ROLES_SUCCESS: "LOAD_ROLES_SUCCESS"
};

export const people = {
  LOAD_PEOPLE_BEGIN: "LOAD_PEOPLE_BEGIN",
  LOAD_PEOPLE_SUCCESS: "LOAD_PEOPLE_SUCCESS"
};

export const user = {
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  LOAD_USERS_START: "LOAD_USERS_START",
  LOAD_USERS_SUCCESS: "LOAD_USERS_SUCCESS",
  LOAD_USERS_FAILURE: "LOAD_USERS_FAILURE",
  SET_USER_ROLE_START: "SET_USER_ROLE_START",
  SET_USER_NAME_START: "SET_USER_NAME_START",
  ADD_USER_START: "ADD_USER_START",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_FAILURE",
  UPDATE_USER_START: "UPDATE_USER_START",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS"
};

export const division = {
  LOAD_DIVISIONS_SUCCESS: "LOAD_DIVISIONS_SUCCESS",
  LOAD_USER_DIVISIONS_SUCCESS: "LOAD_USER_DIVISIONS_SUCCESS",
  ADD_USER_DIVISION_SUCCESS: "ADD_USER_DIVISION_SUCCESS",
  DELETE_USER_DIVISION_SUCCESS: "DELETE_USER_DIVISION_SUCCESS"
};

export const currentEdit = {
  LOCK_TIMESHEET_BEGIN: "LOCK_TIMESHEET_BEGIN",
  LOCK_TIMESHEET_SUCCESS: "LOCK_TIMESHEET_SUCCESS",
  UNLOCK_TIMESHEET_BEGIN: "UNLOCK_TIMESHEET_BEGIN",
  UNLOCK_TIMESHEET_SUCCESS: "UNLOCK_TIMESHEET_SUCCESS",
  LOAD_TIMECARDS_BEGIN: "LOAD_TIMECARDS_BEGIN",
  LOAD_TIMECARDS_SUCCESS: "LOAD_TIMECARDS_SUCCESS",
  LOAD_TIMECARDS_AND_HOURS_BEGIN: "LOAD_TIMECARDS_AND_HOURS_BEGIN",
  LOAD_TIMECARDS_AND_HOURS_SUCCESS: "LOAD_TIMECARDS_AND_HOURS_SUCCESS",
  LOAD_HOURS_BEGIN: "LOAD_HOURS_BEGIN",
  LOAD_HOURS_SUCCESS: "LOAD_HOURS_SUCCESS",
  LOAD_EARNINGS_BEGIN: "LOAD_EARNINGS_BEGIN",
  LOAD_EARNINGS_SUCCESS: "LOAD_EARNINGS_SUCCESS",
  LOAD_COMMENTS_BEGIN: "LOAD_COMMENTS_BEGIN",
  LOAD_COMMENTS_SUCCESS: "LOAD_COMMENTS_SUCCESS",
  ADD_COMMENT_SUCCESS: "ADD_COMMENT_SUCCESS",
  ADD_TIMECARDS: "ADD_TIMECARDS",
  REMOVE_TIMECARD: "REMOVE_TIMECARD",
  UPDATE_TIMECARD_FIELD: "UPDATE_TIMECARD_FIELD",
  UPDATE_TIMECARD_HOURS: "UPDATE_TIMECARD_HOURS",
  ADD_EARNINGS: "ADD_EARNINGS",
  REMOVE_EARNINGS: "REMOVE_EARNINGS",
  UPDATE_EARNINGS_FIELD: "UPDATE_EARNINGS_FIELD",
  SAVE_TIMESHEET_SUCCESS: "SAVE_TIMESHEET_SUCCESS",
  SAVE_TIMECARDS_SUCCESS: "SAVE_TIMECARDS_SUCCESS",
  SAVE_HOURS_SUCCESS: "SAVE_HOURS_SUCCESS",
  SAVE_EARNINGS_SUCCESS: "SAVE_EARNINGS_SUCCESS",
  BEGIN_EDIT: "BEGIN_EDIT",
  END_EDIT: "END_EDIT",
  UPDATE_HASCHANGES: "UPDATE_HASCHANGES",
  CLEAR_CURRENT: "CLEAR_CURRENT",
  UPDATE_NEW_COMMENT: "UPDATE_NEW_COMMENT"
};

export const timeout = {
  RESET_TIMEOUT: "RESET_TIMEOUT"
};

export const health = {
  LOAD_HEALTH_SUCCESS: "LOAD_HEALTH_SUCCESS",
  LOAD_HEALTH_FAILURE: "LOAD_HEALTH_FAILURE"
};
