import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { loadPeople } from "../../redux/actions/personActions";
import PeopleGrid from "./PeopleGrid";
import ColumnFilter from "../common/ColumnFilter";
import { toast } from "react-toastify";
import { filteredColumnOptions, handleFilter, BLANK } from "../../utils/utils";

const PeoplePickerPage = ({ people, loadPeople, ...props }) => {
  const [search, setSearch] = useState(""); // Search texbox
  const [query, setQuery] = useState(""); // Query used after pressing Enter in search box
  const [columnSort, setColumnSort] = useState({
    direction: "",
    column: "",
  });
  const [columnFilters, setColumnFilters] = useState({
    Selected: [],
    ID: [...(props.peopleIds || [])],
    "Last Name": [],
    Division: props.division ? [props.division] : [], // initialize to filter on the division of the timesheet
    Asset: [],
    "Default Position": [],
    TypeToAdd: [...(props.typesToAddDefaults || [])],
  });

  // Load people once at the app level
  useEffect(() => {
    if (people && people.allIds && people.allIds.length > 0) {
      // console.log(people.allIds.length + " people already loaded.");
      return;
    }
    loadPeople().catch((error) => {
      toast.error("Loading people failed: " + error, {
        autoClose: 5000,
        position: toast.POSITION.TOP_CENTER,
      });
    });
  }, [people, loadPeople]);

  // function to get an array of peopleData from the state object.
  const peopleData = () => {
    if (people && people.allIds)
      return people.allIds.map((id) => people.byId[id]);
    else return [];
  };
  // mariners only
  const marinerPeople = () => {
    return peopleData().filter((p) => !p.isShoreside);
  };

  // Map of display names to column names for columns with sort and filters
  const sortableColumns = {
    ID: "id",
    "Last Name": "lastName",
    Division: "division",
    Asset: "homeTug",
    "Default Position": "defaultJob",
  };

  const columnOptions = (colDisplay) => {
    const marinersOnly = !query && columnFilters.ID.length === 0;
    return filteredColumnOptions(
      queriedPeople(marinersOnly),
      sortableColumns,
      columnFilters,
      colDisplay
    );
  };
  const peopleOptions = () => {
    return filteredSortedPeople().map((p) => p.id);
  };

  const handleSearchChange = (evt) => {
    setSearch(evt.target.value);
  };

  const hasFilters = () => {
    return (
      query ||
      Object.keys(columnFilters).reduce((prev, cur) => {
        return columnFilters[cur].length + prev;
      }, 0) > 0
    );
  };

  const clearFilters = () => {
    setColumnSort({ direction: "", column: "" });
    setColumnFilters(
      Object.keys(columnFilters).reduce((prev, cur) => {
        const newVal = { ...prev, [cur]: [] };
        return newVal;
      }, {})
    );
  };

  const handleClearFilters = () => {
    const message =
      "You are about to remove any selections, filters, or sorting you have done; do you want to proceed?";
    if (window.confirm(message)) {
      clearFilters();
      setSearch("");
      setQuery("");
    }
  };

  const handleSearchEnter = (evt) => {
    if (evt.key === "Enter") {
      const message =
        "The search you are about to perform will remove any selections, filters, or sorting you have done; do you want to proceed?";
      // If there's a query, then they already responded to the confirmation
      if (window.confirm(message)) {
        // clear filters
        clearFilters();
        // Set the new search query without the filters
        setQuery(search);
      }
      // }
    }
  };

  const handleSort = (direction, column) => {
    // console.log("Sort: ", column, direction);
    // Clicking the same sort a second time clears the sort
    if (direction === columnSort.direction && column === columnSort.column) {
      setColumnSort({ direction: "", column });
    } else {
      setColumnSort({ direction, column });
    }
  };

  const handleFilterLocal = (value, column) => {
    // Toggle whether the value is included in the set of filter options for this column.
    const visibleOptions =
      column === "TypeToAdd"
        ? props.typesToAddOptions
        : column === "Selected"
        ? peopleOptions()
        : columnOptions(column);
    handleFilter(
      value,
      column,
      columnFilters,
      setColumnFilters,
      visibleOptions
    );
  };

  const handleFilterAll = (selectAll, column) => {
    if (selectAll) {
      setColumnFilters({
        ...columnFilters,
        [column]: columnOptions(column),
      });
    } else {
      setColumnFilters({ ...columnFilters, [column]: [] });
    }
  };

  const handleAddPeople = () => {
    if (columnFilters.Selected.length === 0) {
      toast.error("You must select at least one person to add.");
    } else if (columnFilters.TypeToAdd.length === 0) {
      toast.error(
        `You must select at least one ${props.typesToAddLabel} to add.`
      );
    } else if (
      columnFilters.Selected.length * columnFilters.TypeToAdd.length +
        props.cardCount >
      props.maxCount
    ) {
      toast.error(
        `Your selections of people and ${props.typesToAddLabel} would result in more than the maximum ${props.maxCount} rows on the timesheet. Please reduce your selections and try again.`
      );
    } else {
      if (props.onAddPeople)
        props.onAddPeople(columnFilters.Selected, columnFilters.TypeToAdd);
      handleClose();
    }
  };

  // Filter on each sortable/filterable column's filter array; return the residual
  const filterOnColumns = (data) => {
    return Object.keys(sortableColumns).reduce(
      (prev, cur) =>
        prev.filter(
          (p) =>
            columnFilters[cur].length === 0 ||
            columnFilters[cur].includes(
              !p[sortableColumns[cur]] ? BLANK : p[sortableColumns[cur]]
            )
        ),
      data
    );
  };

  const queryNameMatch = (person, query) => {
    return (
      query &&
      // query === "..." ||
      (person.firstName.toLowerCase().startsWith(query.toLowerCase()) ||
        person.lastName.toLowerCase().startsWith(query.toLowerCase()) ||
        (
          person.firstName.toLowerCase() +
          " " +
          person.lastName.toLowerCase()
        ).startsWith(query.toLowerCase()))
    );
  };

  const queriedPeople = () => {
    return query
      ? peopleData().filter((p) => queryNameMatch(p, query))
      : columnFilters.ID.length === 0
      ? marinerPeople()
      : peopleData();
  };

  const filteredSortedPeople = () => {
    // console.log(columnFilters);
    // console.log(filterOnColumns(peopleData()));
    return filterOnColumns(peopleData())
      .filter(
        (p) =>
          (!query && !p.isShoreside) ||
          (!query && columnFilters.ID.length > 0) ||
          queryNameMatch(p, query)
      )
      .sort((a, b) => {
        if (!columnSort.column || !columnSort.direction) {
          return a.lastName === b.lastName
            ? 0
            : a.lastName < b.lastName
            ? -1
            : 1;
        } else {
          let aVal = a[sortableColumns[columnSort.column]];
          let bVal = b[sortableColumns[columnSort.column]];
          return aVal === bVal
            ? 0
            : aVal < bVal
            ? columnSort.direction === "Asc"
              ? -1
              : 1
            : columnSort.direction === "Asc"
            ? 1
            : -1;
        }
      });
  };

  const handleClose = () => {
    if (props.onClose) props.onClose();
    else {
      props.history.goBack();
    }
  };

  const handleAddTypeCheck = (value, column) => {
    handleFilterLocal(value, "TypeToAdd");
  };

  const handleCheckAll = (checked) => {
    if (checked)
      setColumnFilters((prev) => ({
        ...prev,
        Selected: filteredSortedPeople().map((d) => d.id),
      }));
    else setColumnFilters((prev) => ({ ...prev, Selected: [] }));
  };

  return (
    <>
      <div className="modal-content">
        <div className="people-header">
          <div className="people-header-search">
            <input
              className="search"
              placeholder="Type search criteria and press enter"
              value={search}
              onChange={handleSearchChange}
              onKeyPress={handleSearchEnter}
            />
            {hasFilters() && (
              <button
                onClick={handleClearFilters}
                title="Remove all filters and sorting. 
              This will show all mariners but not shoreside; 
              you must search for shoreside employees."
              >
                Clear Filters
              </button>
            )}
            {/* {props.cardCount} + {columnFilters.Selected.length} *{" "}
            {columnFilters.TypeToAdd.length} ={" "}
            {props.cardCount +
              columnFilters.Selected.length * columnFilters.TypeToAdd.length}
            {props.cardCount +
              columnFilters.Selected.length * columnFilters.TypeToAdd.length >
            props.maxCount
              ? ">"
              : "<="}
            {props.maxCount} */}
          </div>
          <div className="people-header-types">
            <div className="header-left">
              <div className="header-left label">
                {props.typesToAddLabel} to be added:
              </div>
              <div className="types-to-add">
                {(columnFilters.TypeToAdd || []).join(", ")}
              </div>
            </div>
          </div>
          <div className="people-header-buttons">
            <ColumnFilter
              label={<> -&nbsp;Please Select&nbsp;- </>}
              options={props.typesToAddOptions}
              selectedOptions={columnFilters.TypeToAdd}
              showFilterIcon={false}
              showSort={false}
              hideCheckAll={true}
              checkCallback={handleAddTypeCheck}
              className="header-left select"
            />
          </div>
          <div className="people-header-buttons">
            <button onClick={handleAddPeople}>Add to Timesheet</button>
          </div>
        </div>
        <div className="scrollable">
          <PeopleGrid
            people={filteredSortedPeople()}
            division={props.division}
            lastnameOptions={columnOptions("Last Name")}
            divisionOptions={columnOptions("Division")}
            assetOptions={columnOptions("Asset")}
            positionOptions={columnOptions("Default Position")}
            columnFilters={columnFilters}
            onSort={handleSort}
            onFilter={handleFilterLocal}
            onFilterCheckAll={handleFilterAll}
            onCheckAll={handleCheckAll}
          />
          <p></p>
        </div>
      </div>
    </>
  );
};

// Redux
function mapStateToProps(state) {
  return { people: state.people };
}
const mapDispatchToProps = { loadPeople };

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePickerPage);
