import React from "react";
import ColumnFilter from "../common/ColumnFilter";
import Checkbox from "../common/Checkbox";

const PeopleGrid = props => {
  const handleSort = (direction, column) => {
    if (props.onSort) props.onSort(direction, column);
  };

  const handleCheck = (value, column) => {
    if (props.onFilter) props.onFilter(value, column);
  };
  const handleFilterAll = (checked, column) => {
    if (props.onFilterCheckAll) props.onFilterCheckAll(checked, column);
  };

  const handleSelect = value => {
    handleCheck(value, "Selected");
  };
  const handleCheckAll = evt => {
    if (props.onCheckAll) props.onCheckAll(evt.target.checked);
  };

  return (
    <div className="people-grid-container">
      <table className="people-grid">
        <thead>
          <tr>
            <th className="col-one-half">
              <input type="checkbox" onChange={handleCheckAll}></input>
            </th>
            <th className="col-one">ID</th>
            <th className="col-one" title="First Name">
              First Name
            </th>
            <th className="col-one" title="Middle Name">
              Middle Name
            </th>
            <th className="col-one" title="Last Name">
              <ColumnFilter
                label="Last Name"
                options={props.lastnameOptions}
                selectedOptions={props.columnFilters["Last Name"]}
                sortCallback={handleSort}
                checkCallback={handleCheck}
                onCheckAll={handleFilterAll}
                showFilterIcon={true}
                showSort={true}
              />
            </th>
            <th className="col-one-half" title="Suffix">
              Suffix
            </th>
            <th className="col-three" title="Division">
              <ColumnFilter
                label="Division"
                options={props.divisionOptions}
                selectedOptions={props.columnFilters["Division"]}
                sortCallback={handleSort}
                checkCallback={handleCheck}
                onCheckAll={handleFilterAll}
                showFilterIcon={true}
                showSort={true}
              />
            </th>
            <th className="col-two" title="Asset">
              <ColumnFilter
                label="Asset"
                options={props.assetOptions}
                selectedOptions={props.columnFilters["Asset"]}
                sortCallback={handleSort}
                checkCallback={handleCheck}
                onCheckAll={handleFilterAll}
                showFilterIcon={true}
                showSort={true}
              />
            </th>
            <th className="col-two" title="Default Position">
              <ColumnFilter
                label="Default Position"
                options={props.positionOptions}
                selectedOptions={props.columnFilters["Default Position"]}
                sortCallback={handleSort}
                checkCallback={handleCheck}
                onCheckAll={handleFilterAll}
                showFilterIcon={true}
                showSort={true}
                className="last-column-filter"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {props.people.map((p, i) => (
            <tr key={p.id} className={i % 2 ? "normal-bkg" : "alternate-bkg"}>
              <td>
                <Checkbox
                  label=""
                  value={p.id}
                  isSelected={props.columnFilters["Selected"].includes(p.id)}
                  onCheckChange={() => handleSelect(p.id)}
                />
              </td>
              <td>{p.moranId}</td>
              <td>{p.firstName}</td>
              <td>{p.middleName}</td>
              <td>{p.lastName}</td>
              <td>{p.nameSuffix}</td>
              <td>{p.division}</td>
              <td>{p.homeTug}</td>
              <td>{p.defaultJob}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PeopleGrid;
