import AuthService from "../auth/authService";
import { config } from "../tools/config";

const JSON_HEADER = { "Content-Type": "application/json" };
const auth = new AuthService();
const authKeyBase =
  "CognitoIdentityServiceProvider." + config.env.REACT_APP_AUTH_CLIENT_ID + ".";

const getIdToken = () => {
  // Check for the need to refresh the idToken
  const timeLeft = auth.getTimeToExpiration(); // in seconds
  if (timeLeft < 60 * 15) {
    console.log(timeLeft, "refresh token");
    auth.refreshToken();
  }
  const lastAuthUserKey = authKeyBase + "LastAuthUser";
  const userName = localStorage.getItem(lastAuthUserKey);
  const tokenKey = authKeyBase + userName + ".idToken";
  const idToken = localStorage.getItem(tokenKey);
  // console.log("idToken", idToken);
  return idToken;
};
export const getAuthHeader = () => {
  return { ...JSON_HEADER, Authorization: "Bearer " + getIdToken() };
};

export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400 || response.status === 500) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 403) {
    throw new Error("User is not authorized to execute this API");
  }
  console.error("status", response.status);
  // throw new Error("Network response was not ok.");
}

export async function handlePutResponse(response) {
  // if (response.status === 204)
  if (response.status === 404) throw new Error("404 - PUT endpoint not found.");
  return response.text().then(res => getPromise(response, "Update", res));
  // else return response.text().then(res => getPromise(response, "Update", res));
}

export async function handlePostResponse(response) {
  if (response.ok)
    return response.json().then(res => getPromise(response, "Add", res));
  else return response.text().then(res => getPromise(response, "Add", res));
}

export async function handleDeleteResponse(response) {
  if (response.ok)
    return response.json().then(res => getPromise(response, "Delete", res));
  else return response.text().then(res => getPromise(response, "Delete", res));
}

// TODO: Maybe call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

// Helper
const getPromise = (response, action, result) => {
  return Promise.resolve({
    action,
    ok: response.ok,
    result,
    status: response.status,
    innerResponse: response
  });
};
