import React from "react";
import {
  formatShortDate,
  formatNumber,
  formatDollars,
  viewableCards,
  safeNumber,
  SHORESIDE_CHECK
} from "../../utils/utils";
import CheckNumberDropdown from "../common/CheckNumberDropdown";
import { hasPerm, perm } from "../../utils/userUtils";
import { connect } from "react-redux";

const TimeSheetHours = ({ canEdit, ...props }) => {
  const dayOffsets = Array.from({ length: 14 }, (a, i) => i);
  const hoursRegEx = /^\d{0,2}(\.\d{0,2})?$/;

  const handleHoursBlur = (evt, cardId, index) => {
    if (canEdit) {
      // console.log(cardId, evt.target.value);
      let value = formatNumber(evt.target.value);
      value = value === "" ? null : value;
      if (props.onHoursChange) props.onHoursChange(cardId, index, value);
    }
  };

  const selectOnFocus = evt => {
    evt.target.select();
  };

  const handleHoursChange = (evt, cardId, index) => {
    if (canEdit) {
      const value = evt.target.value;
      if (hoursRegEx.test(value)) {
        props.onHoursChange(cardId, index, value);
      }
    }
  };

  const handleFieldChange = (cardId, fieldName, value) => {
    if (canEdit && cardId && props.onFieldChange)
      props.onFieldChange("UPDATE_FIELD", cardId, fieldName, value);
  };

  function getHoursSummary() {
    if (props.timeSheetRows.length === 0) return [];
    const uniqueTypes = [
      ...new Set(
        props.timeSheetRows
          .map(r => r.hoursType)
          .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
      )
    ];
    // console.log(uniqueTypes);
    const hoursSummary = uniqueTypes.map(ht =>
      props.timeSheetRows
        .filter(r => r.hoursType === ht)
        .reduce(
          (typeSum, row) => {
            // console.log(row);
            // console.log(typeSum.hours);
            return {
              ...typeSum,
              hours: ((row && row.hours) || Array.from({ length: 14 })).map(
                (h, i) =>
                  (typeSum.hours[i] =
                    (typeSum.hours[i] || 0) +
                    (h ? safeNumber(h.hours) || 0 : 0))
              )
            };
          },
          { hoursType: ht, hours: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] }
        )
    );
    return hoursSummary;
  }

  const canUserViewDivision = divisionId => {
    // console.log("can user view division", divisionId, props.user.divisions);
    return (props.user.divisions || []).includes(divisionId);
  };

  const getRate = tc => {
    return hasPerm(props.user, perm.ORV)
      ? tc.overrideRate
        ? "$" + formatDollars(tc.paidRate)
        : canUserViewDivision(tc.divisionId)
        ? "$" + formatDollars(tc.defaultRate || 0)
        : ""
      : "";
  };

  return (
    <div className="timesheet-container">
      <table>
        <thead>
          <tr>
            <th className="col-one-half" title="Emp ID">
              Emp ID
            </th>
            <th className="col-two" title="Crew Members">
              Crew Members
            </th>
            <th className="col-one" title="Hours Type">
              Hours Type
            </th>
            <th className="col-one" title="Position">
              Position
            </th>
            <th className="column-rate" title="Rate">
              Rate
            </th>
            <th className="end-column column-rate" title="Check #">
              Check #
            </th>
            {dayOffsets.map(i => (
              <th key={i} className="hours">
                {formatShortDate(props.startDate, i)}
              </th>
            ))}
            <th className="start-column hours">Total</th>
          </tr>
        </thead>
        <tbody>
          {viewableCards(props.timeSheetRows).map((tc, i) => {
            const safeHours = (tc.hours || Array.from({ length: 14 })).map(h =>
              h ? h.hours : 0
            );
            return (
              <tr
                key={tc.timecardId}
                className={i % 2 === 1 ? "normal-bkg" : "alternate-bkg"}
              >
                <td title={tc.moranId}>{tc.moranId}</td>
                <td title={tc.employeeName}>{tc.employeeName}</td>
                <td title={tc.hoursType}>{tc.hoursType}</td>
                <td title={tc.position}>{tc.position}</td>
                <td title={getRate(tc)} className="number">
                  {getRate(tc)}
                </td>
                <td title={tc.checkNum} className="end-column number">
                  {hasPerm(props.user, perm.ORV) &&
                    (tc.isShoreside && tc.checkNum === SHORESIDE_CHECK ? (
                      tc.checkNum
                    ) : (
                      <>
                        <CheckNumberDropdown
                          checkNum={tc.checkNum}
                          onChange={v =>
                            handleFieldChange(tc.timecardId, "checkNum", v)
                          }
                          canEdit={canEdit}
                        />
                      </>
                    ))}
                </td>
                {safeHours.map((hours, i) => (
                  <td
                    key={i}
                    className="hours"
                    title={formatShortDate(props.startDate, i)}
                  >
                    {canEdit ? (
                      <input
                        type="text"
                        className="hours number"
                        value={hours || ""}
                        onFocus={selectOnFocus}
                        onChange={e => handleHoursChange(e, tc.timecardId, i)}
                        onBlur={e => handleHoursBlur(e, tc.timecardId, i)}
                        disabled={!canEdit}
                      />
                    ) : (
                      hours
                    )}
                  </td>
                ))}
                <td className="hours number start-column">
                  {formatNumber(
                    safeHours.reduce((sum, h) => (sum += safeNumber(h) || 0), 0)
                  ) || "0.00"}
                </td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="21" className="timesheet-summary hours">
              Total Summary
            </td>
          </tr>
          {getHoursSummary().map((s, i) => (
            <tr key={i}>
              <td colSpan="6" className="timesheet-summary">
                {s.hoursType} Hours:
              </td>
              {s.hours.map((h, hi) => (
                <td key={hi} className="hours">
                  {formatNumber(h)}
                </td>
              ))}
              <td className="hours start-column">
                {formatNumber(s.hours.reduce((sum, h) => (sum += h || 0), 0)) ||
                  "0.00"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Redux
function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(TimeSheetHours);
