import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { loadRoles } from "../../../redux/actions/lookupActions";
import Dropdown from "../../common/Dropdown";
import { addUser, updateUser } from "../../../redux/actions/userActions";
import {
  loadDivisions,
  loadUserDivisions,
  addUserDivisions,
  deleteUserDivisions
} from "../../../redux/actions/divisionActions";
import { toast } from "react-toastify";
import UserDivision from "./UserDivisions";

const EditUser = ({
  selectedUserId,
  setSelectedUserId,
  users,
  secRoles,
  loadRoles,
  addUser,
  updateUser,
  divisions,
  needToLoad,
  loadDivisions,
  loadUserDivisions,
  addUserDivisions,
  deleteUserDivisions,
  editing,
  setEditing
}) => {
  const emptyUser = { fullName: "", name: "", roleId: 0, isNew: true };
  const [thisUser, setThisUser] = useState(emptyUser);
  const [userDivisions, setUserDivisions] = useState([]);
  const commentEl = useRef();
  useEffect(() => {
    commentEl.current.innerHTML = `<!-- User ID = ${selectedUserId} -->`;
  }, [selectedUserId]);
  useEffect(() => {
    if (!secRoles || secRoles.length === 0) {
      loadRoles();
    }
  }, [loadRoles, secRoles]);
  useEffect(() => {
    if (selectedUserId && users && users.byId[selectedUserId])
      setThisUser(users.byId[selectedUserId]);
  }, [selectedUserId, setThisUser, users]);
  useEffect(() => {
    if (needToLoad) loadDivisions();
  }, [needToLoad, loadDivisions]);
  useEffect(() => {
    if (selectedUserId && users && users.byId[selectedUserId])
      if (!users.byId[selectedUserId].divisions) {
        // console.log("load user divisions");
        loadUserDivisions(selectedUserId);
      } else setUserDivisions(users.byId[selectedUserId].divisions);
  }, [selectedUserId, users, loadUserDivisions, editing]);

  const roles = () => {
    // Convert secRoles objects to {id, description} for dropdown
    return (secRoles || []).map(r => ({
      id: r.id,
      description: r.name
    }));
  };

  const createUser = () => {
    setThisUser(emptyUser);
    setUserDivisions([]);
    setEditing(true);
  };

  const handleFieldChange = evt => {
    const { id, value } = evt.target;
    // console.log(id, value);
    if (id && (value || value === ""))
      setThisUser(u => ({ ...u, [id]: value }));
  };
  const handleRoleChange = (id, field, value) => {
    setThisUser(u => ({ ...u, [field]: value }));
  };

  const isDuplicateUser = (userName, userId) => {
    // console.log(users);
    return users.allIds.some(id => {
      // console.log(id, users.byId[id]);
      return (
        users.byId[id].name.toLowerCase() === userName.toLowerCase() &&
        id !== userId
      );
    });
  };

  const userDivsAdded = () => {
    const originalDivs = users.byId[selectedUserId].divisions;
    // console.log("original", originalDivs);
    const added = userDivisions
      // .map(ud => ud.id)
      .filter(ud => !originalDivs.some(od => od.id === ud.id));
    return added;
  };
  const userDivsRemoved = () => {
    const originalDivs = users.byId[selectedUserId].divisions;
    const removed = originalDivs
      // .map(od => od.id)
      .filter(od => !userDivisions.some(ud => ud.id === od.id));
    return removed;
  };

  const handleSave = () => {
    // Validate: user name, full name, and role are required
    if (thisUser.name && thisUser.fullName && thisUser.roleId) {
      // Valid; continue
      if (!isDuplicateUser(thisUser.name, selectedUserId)) {
        if (thisUser.isNew) {
          addUser(thisUser)
            .then(resp => {
              // console.log(resp);
              // TODO: Deal with duplicate email address (user id). The DB doesn't add a dupe, but here we add divisions to the existing user without notice.
              if (resp && resp.result && resp.result.id) {
                return addUserDivisions(
                  resp.result.id,
                  userDivisions
                ).then(() => setSelectedUserId(resp.result.id));
              } else {
                toast.warn(
                  "Divisions could not be set for the user. Please edit the user and try again."
                );
              }
            })
            .then(() => {
              toast.success(`${thisUser.fullName} added.`);
              setThisUser(emptyUser);
              setEditing(false);
            })
            .catch(error => {
              console.error(error);
              toast.error("Failed to add user.");
            });
        } else {
          updateUser(thisUser)
            .then(() => deleteUserDivisions(selectedUserId, userDivsRemoved()))
            .then(() => addUserDivisions(selectedUserId, userDivsAdded()))
            .then(() => loadUserDivisions(selectedUserId))
            .then(() => {
              toast.success(`${thisUser.fullName} updated.`);
              setEditing(false);
            })
            .catch(error => {
              console.error(error);
              toast.error("Failed to update user.");
            });
        }
      } else {
        toast.warn(`There is already a user with the email ${thisUser.name}`);
      }
    } else {
      // Not valid
      toast.warn("Please complete all the fields.");
    }
  };
  const handleCancel = () => {
    if (selectedUserId && users && users.byId[selectedUserId])
      setThisUser(users.byId[selectedUserId]);
    else {
      setThisUser(emptyUser);
      setUserDivisions([]);
    }
    setEditing(false);
  };

  return (
    <div className="user-selection-container">
      {selectedUserId || editing ? (
        <>
          <h2>Selected User: {thisUser.fullName}</h2>
          <div className="user-edit-container">
            <div className="user-edit-fields">
              {/* <div>{selectedUserId}</div>
              <div>{(!thisUser.name).toString()}</div> */}
              <div>
                <span className="user-field-label">User Name (Email):</span>
              </div>
              <div>
                <input
                  type="text"
                  id="name"
                  value={thisUser.name}
                  onChange={handleFieldChange}
                  disabled={!editing}
                />
              </div>
              <div>
                <span className="user-field-label">Full Name:</span>
              </div>
              <div>
                <input
                  type="text"
                  id="fullName"
                  value={thisUser.fullName || ""}
                  onChange={handleFieldChange}
                  disabled={!editing}
                />
              </div>
              <div>
                <span className="user-field-label">Role:</span>
              </div>
              <div>
                <Dropdown
                  types={roles()}
                  id={thisUser.id || -1}
                  selectedValue={thisUser.roleId}
                  selectedText={thisUser.roleName}
                  onChange={handleRoleChange}
                  idColumn="roleId"
                  textColumn="roleName"
                  canEdit={editing}
                />
              </div>
              <div className="grid-button-row">
                {editing ? (
                  <>
                    <button type="button" onClick={handleSave}>
                      Save
                    </button>
                    <button type="button" onClick={handleCancel}>
                      Cancel
                    </button>
                  </>
                ) : (
                  <button type="button" onClick={() => setEditing(true)}>
                    Edit
                  </button>
                )}
              </div>
            </div>
            <UserDivision
              selectedUserId={selectedUserId}
              canEdit={editing}
              divisions={divisions}
              userDivisions={userDivisions}
              setUserDivisions={setUserDivisions}
            />
          </div>
        </>
      ) : (
        <>
          <h2>Edit/Create User</h2>
          <div>
            Select a user to edit, or click the button to create a new user.
          </div>
          <div>
            <button type="button" onClick={createUser}>
              New User
            </button>
          </div>
        </>
      )}
      <div ref={commentEl}></div>
    </div>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    users: state.users,
    secRoles: state.roles,
    divisions: state.divisions,
    needToLoad: state.divisions && state.divisions.needToLoad
  };
}
const mapDispatchToProps = {
  loadRoles,
  addUser,
  updateUser,
  loadDivisions,
  loadUserDivisions,
  addUserDivisions,
  deleteUserDivisions
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
