import React, { useState, useEffect } from "react";
import { getMissingTimesheets } from "../../api/timesheetApi";
import Checkbox from "../common/Checkbox";
import ColumnFilter from "../common/ColumnFilter";
import {
  handleSort,
  handleFilter,
  filterOnColumns,
  filteredColumnOptions
} from "../../utils/utils";
import { addTimesheet } from "../../redux/actions/timesheetActions";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import Error from "../common/Error";

const TimesheetMgmtPage = props => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    getMissingTimesheets()
      .then(sheets => setData(sheets))
      .catch(error => {
        console.error("Loading available timesheets failed. " + error);
        setErrorMessage("The timesheets could not be loaded.");
      })
      .finally(() => setLoading(false));
  }, []);

  // Configuration //
  const sortableColumns = {
    Division: "division",
    Asset: "asset"
  };
  // I don't like the repetition of sort/filter state and event handlers on each page that needs them, but not sure of the better way to do it yet
  const [columnSort, setColumnSort] = useState({
    direction: "Asc",
    column: ""
  });
  const [columnFilters, setColumnFilters] = useState({
    Asset: [],
    Division: props.divisions || []
  });

  // Event handlers //
  const handleClose = () => {
    if (props.onClose) props.onClose();
  };

  const handleCheckChange = id => {
    if (selected.includes(id)) setSelected(prev => prev.filter(s => s !== id));
    else setSelected(prev => [...prev, id]);
  };

  const handleCheckAll = evt => {
    // console.log(evt, evt.target.checked);
    if (evt.target.checked)
      setSelected(
        filterOnColumns(data, sortableColumns, columnFilters).map(
          d => d.assetId
        )
      );
    else setSelected([]);
  };

  const handleGenerateTimesheets = () => {
    if (generating) return;
    setGenerating(true);
    // console.log(selected, data);
    const sheets = selected
      .map(assetId => data.find(d => d.assetId === assetId))
      .map(d => getTimesheet(d));
    props.addTimesheet(sheets).then(() => handleClose());
  };

  const handleSortLocal = (direction, column) => {
    // pass local variables to common handler
    handleSort(direction, column, columnSort, setColumnSort);
  };

  const handleFilterLocal = (value, column) => {
    const visibleOptions = stringOptions(column);
    handleFilter(
      value,
      column,
      columnFilters,
      setColumnFilters,
      visibleOptions
    );
  };
  const handleFilterAll = (selectAll, column) => {
    if (selectAll) {
      setColumnFilters({
        ...columnFilters,
        [column]: stringOptions(column)
      });
    } else {
      setColumnFilters({ ...columnFilters, [column]: [] });
    }
  };

  // Helper functions //
  const stringOptions = column => {
    return filteredColumnOptions(data, sortableColumns, columnFilters, column);
  };

  const getTimesheet = dataItem => {
    const { id, ...sheet } = dataItem;
    return sheet;
  };

  const filteredSortedData = data => {
    return filterOnColumns(data, sortableColumns, columnFilters).sort(
      (a, b) => {
        let aVal = a[sortableColumns[columnSort.column]];
        let bVal = b[sortableColumns[columnSort.column]];
        // Default sort by 2 columns
        if (!columnSort.column) {
          aVal = a.division + ";#" + a.asset;
          bVal = b.division + ";#" + b.asset;
        }
        return aVal === bVal
          ? 0
          : aVal < bVal
          ? columnSort.direction === "Asc"
            ? -1
            : 1
          : columnSort.direction === "Asc"
          ? 1
          : -1;
      }
    );
  };

  return (
    <>
      <div className="modal-content">
        <div className="direction-summary">
          Assets only show under the following conditions:
          <ol>
            <li>You have access to its division.</li>
            <li>
              For the division's current pay period, the asset does not have an
              associated timesheet.
            </li>
          </ol>
        </div>
        {loading ? (
          <Spinner />
        ) : errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <>
            <div className="dialog-fixed">
              <div className="header-right">
                <button
                  type="button"
                  onClick={handleGenerateTimesheets}
                  disabled={generating}
                >
                  {generating ? "Please wait..." : "Generate Timesheets"}
                </button>
              </div>
            </div>
            <div className="scrollable">
              <table className="time-management-grid">
                <thead>
                  <tr>
                    <th className="col-one">
                      <input type="checkbox" onChange={handleCheckAll} />
                    </th>
                    <th className="col-five">
                      <ColumnFilter
                        label="Asset"
                        options={stringOptions("Asset")}
                        selectedOptions={columnFilters["Asset"]}
                        sortCallback={handleSortLocal}
                        checkCallback={handleFilterLocal}
                        onCheckAll={handleFilterAll}
                        showFilterIcon={true}
                        showSort={true}
                        className="time-management-filter"
                      />
                    </th>
                    <th className="col-six">
                      <ColumnFilter
                        label="Division"
                        options={stringOptions("Division")}
                        selectedOptions={columnFilters["Division"]}
                        sortCallback={handleSortLocal}
                        checkCallback={handleFilterLocal}
                        onCheckAll={handleFilterAll}
                        showFilterIcon={true}
                        showSort={true}
                        className="time-management-filter"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSortedData(data).map((d, i) => (
                    <tr
                      key={d.assetId}
                      className={i % 2 ? "alternate-bkg" : "normal-bkg"}
                    >
                      <td>
                        <label>
                          <Checkbox
                            id={d.assetId}
                            // label={d.assetId}
                            value={d.assetId}
                            isSelected={selected.includes(d.assetId)}
                            onCheckChange={() => handleCheckChange(d.assetId)}
                          />
                        </label>
                      </td>
                      <td>{d.asset}</td>
                      <td>{d.division}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p></p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

// Redux
function mapStateToProps(state) {
  return {};
}
const mapDispatchToProps = { addTimesheet };

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetMgmtPage);
