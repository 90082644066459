import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortAlphaDown,
  faSortAlphaUp
} from "@fortawesome/free-solid-svg-icons";
import Checkbox from "./Checkbox";
import { REGULAR } from "../../utils/utils";

const ColumnFilter = props => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const container = useRef(null);

  // Find the closest parent element that is either a modal window or the body element.
  const scrollParent = el => {
    if (!el || !el.offsetParent) return el;
    if (el.localName && el.localName.toLowerCase() === "body") return el;
    if (el.className && el.className.trim() === "modal") return el;
    if (el.className && el.className.trim() === "scrollable") return el;
    // recurse up parents
    return scrollParent(el.offsetParent);
  };

  useEffect(() => {
    const hideHandler = e => {
      // console.log(e, container);
      // console.log(e.layerX, scrollParent(container.current).scrollWidth);
      if (
        !container.current.contains(e.target) &&
        e.layerX < scrollParent(container.current).scrollWidth
      ) {
        close();
      }
    };
    if (optionsVisible) {
      document.addEventListener("mouseup", hideHandler);
      return () => {
        document.removeEventListener("mouseup", hideHandler);
      };
    }
    // Since scrollParent does not work in the dependencies array...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsVisible]);

  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
    // console.log(optionsVisible);
  };

  const handleSort = (direction, column) => {
    if (typeof props.sortCallback === "function") {
      props.sortCallback(direction, column);
    }
    toggleOptions();
  };

  const handleCheck = value => {
    if (typeof props.checkCallback === "function") {
      // console.log(value);
      props.checkCallback(value, props.label);
    }
  };

  const handleCheckAll = evt => {
    if (typeof props.onCheckAll === "function") {
      props.onCheckAll(evt.target.checked, props.label);
    }
  };

  const close = () => {
    setOptionsVisible(false);
  };

  return (
    <div className={props.className} ref={container}>
      <div className="clickable" onClick={toggleOptions}>
        {props.label}{" "}
        {props.showFilterIcon && (
          <FontAwesomeIcon
            icon={faFilter}
            className={
              props.selectedOptions && props.selectedOptions.length
                ? "active"
                : ""
            }
          />
        )}
      </div>
      <div className="filter-popdown-container">
        <div
          className={
            optionsVisible ? "filter-popdown visible" : "filter-popdown"
          }
        >
          {props.showSort && (
            <>
              <div
                className="clickable"
                onClick={() => handleSort("Asc", props.label)}
              >
                <FontAwesomeIcon icon={faSortAlphaDown} /> Sort Ascending
              </div>
              <div
                className="clickable"
                onClick={() => handleSort("Desc", props.label)}
              >
                <FontAwesomeIcon icon={faSortAlphaUp} /> Sort Descending
              </div>
              <hr />
            </>
          )}
          <div>
            {!props.hideCheckAll && (
              <div className="select-all">
                <Checkbox
                  label="(Select All)"
                  value="ALL"
                  isSelected={
                    props.options.length === props.selectedOptions.length
                  }
                  onCheckChange={handleCheckAll}
                />
              </div>
            )}
            {(props.options || []).map(opt => (
              <div key={opt} className={opt === REGULAR ? "top-choice" : ""}>
                <Checkbox
                  label={opt}
                  value={opt}
                  isSelected={(props.selectedOptions || []).includes(opt)}
                  onCheckChange={() => handleCheck(opt)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnFilter;
