import React from "react";

const Checkbox = ({
  label,
  value,
  isSelected,
  onCheckChange,
  id,
  disabled
}) => (
  <label>
    <input
      id={id}
      type="checkbox"
      value={value}
      checked={isSelected}
      onChange={onCheckChange}
      disabled={disabled}
    />
    {label}
  </label>
);

export default Checkbox;
