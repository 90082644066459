import { safeNumber } from "../utils/utils";

export const totalHours = timeCards => {
  return (timeCards || [])
    .filter(c => !c || !c.metadata || !c.metadata.deleted)
    .reduce(
      (h, tc) =>
        (h += ((tc && tc.hours) || []).reduce(
          (sum, hr) => (sum += hr ? safeNumber(hr.hours) || 0 : 0),
          0
        )),
      0
    );
};

// Sum hours for each timeCard and multiply by the rate; sum those for all cards
export const totalHoursDollars = timeCards => {
  return (timeCards || [])
    .filter(c => !c || !c.metadata || !c.metadata.deleted)
    .reduce(
      (h, tc) =>
        (h +=
          ((tc && tc.hours) || []).reduce(
            (sum, hr) => (sum += hr ? safeNumber(hr.hours) || 0 : 0),
            0
          ) *
          ((tc.overrideRate
            ? safeNumber(tc.paidRate)
            : safeNumber(tc.defaultRate)) || 0)),
      0
    );
};

export const totalEarnings = earningsCards => {
  return (earningsCards || [])
    .filter(c => !c.metadata || !c.metadata.deleted)
    .reduce((total, ec) => (total += safeNumber(ec.amount)), 0);
};
