import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ModalHeader = ({ title, handleClose }) => {
  return (
    <div className="modal-header">
      {title || <>&nbsp;</>}
      <div className="timecard-buttons">
        <div className="close-button">
          <div className="clickable" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="close" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
