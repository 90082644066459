import { timeout } from "../actions/actionTypes";
import initialState from "./initialState";

export default function timeoutReducer(state = initialState.timeout, action) {
  switch (action.type) {
    case timeout.RESET_TIMEOUT:
      return { lastActive: Date.now() };
    default:
      // anything that touches state resets the activity
      return { lastActive: Date.now() };
    // return state;
  }
}
