import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function personReducer(state = initialState.people, action) {
  switch (action.type) {
    case types.people.LOAD_PEOPLE_BEGIN:
      return state;
    case types.people.LOAD_PEOPLE_SUCCESS:
      return {
        byId: action.people.reduce((collector, person) => {
          return { ...collector, [person.id]: person };
        }, {}),
        allIds: action.people.map(p => p.id)
      };
    default:
      return state;
  }
}
