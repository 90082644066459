// import { perm, role } from "../../utils/userUtils";

// This file describes the structure of the state managed in Redux.
// Any data included (beyond the structure) is only for illustration or testing
//  and should not be treated as real data for the system.
export default {
  apiStatus: {
    status: "",
    date: "",
    version: ""
  },
  earningTypes: [],
  hoursTypes: [],
  positions: [],
  roles: [],
  divisions: { byId: {}, allIds: [], needToLoad: true },
  payPeriods: { byId: {}, allIds: [], needToLoad: true },
  timesheets: { byId: {}, allIds: [], needToLoad: true, isFetching: false },
  people: { byId: {}, allIds: [], needToLoad: true, isFetching: false },
  dataExport: { payPeriod: {}, lastUpdated: null, isFetching: false, data: [] },
  // user is the current system user
  user: { needToLoad: true
    /*
    id: 13,
    fullName: "Dan White",
    token: "TEST_USER",
    roleCode: role.PA,
    permissions: [
      // Role: TK
      perm.AE,
      perm.OP,
      perm.EEH,
      // Role: PP
      perm.CT,
      perm.ROT,
      perm.ORV,
      perm.EET,
      perm.AT,
      perm.AFLO,
      perm.VTA,
      // Role: PA
      perm.ED,
      perm.MPP,
      perm.OPP,
      perm.CPP,
      perm.ET,
      perm.CSV,
      perm.VCTK,
      perm.VCPP,
      perm.VCPA
    ],
    divisions: [10]
    */
  },
  // users is a list of users only loaded by an admin
  users: { byId: {}, allIds: [], needToLoad: true },
  currentEdit: {
    isEditing: false,
    hasChanges: false
    /*
    // timesheet: {
    //   id: 11345,
    //   payPeriodId: 0,
    //   assetId: 0,
    //   divisionId: 0,
    //   lockedOn: null,
    //   lockedBy: null,
    //   payPeriodStart: "2019-08-22T00:00:00",
    //   payPeriodEnd: "2019-09-04T00:00:00",
    //   asset: "Handy Three",
    //   division: "San Juan PR",
    //   status: "Closed",
    //   hours: 672.0,
    //   earnings: 9300.0
    // },
    timecards: [
      {
        id: 1,
        employeeId: 1292,
        employeeName: "Jack Sparrow",
        hoursType: "Regular",
        division: "San Juan PR",
        defaultJob: "Captain",
        defaultRate: 48.0,
        overrideRate: false,
        paidPosition: "Captain",
        paidRate: "",
        hours: [
          {
            id: 1,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-02T00:00:00",
            hours: 12
          },
          {
            id: 2,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-03T00:00:00",
            hours: 12
          },
          {
            id: 3,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-04T00:00:00",
            hours: 12
          },
          {
            id: 4,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-05T00:00:00",
            hours: 12
          },
          {
            id: 5,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-06T00:00:00",
            hours: 12
          },
          {
            id: 6,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-07T00:00:00",
            hours: 12
          },
          {
            id: 7,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-08T00:00:00",
            hours: 12
          },
          {
            id: 8,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-09T00:00:00",
            hours: 12
          },
          {
            id: 9,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-10T00:00:00",
            hours: 12
          },
          {
            id: 10,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-11T00:00:00",
            hours: null
          },
          {
            id: 11,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-12T00:00:00",
            hours: 12
          },
          {
            id: 12,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-13T00:00:00",
            hours: 12
          },
          {
            id: 13,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-14T00:00:00",
            hours: 12
          },
          {
            id: 14,
            timecardId: 1,
            timecard: null,
            workedOn: "2019-11-15T00:00:00",
            hours: 12
          }
        ]
      }
    ],
    earnings: [
      {
        earningsCardId: 1,
        employeeId: 1292,
        employeeName: "Jack Sparrow",
        position: "Captain",
        earningType: "Grub",
        earningTypeId: 7,
        amount: 2200,
        checkNum: 1
      },
      {
        earningsCardId: 2,
        employeeId: 1292,
        employeeName: "Jack Sparrow",
        position: "Captain",
        earningType: "Travel Reimbursement",
        earningTypeId: 13,
        amount: 500,
        checkNum: 2
      }
    ],
    comments: [
      {
        id: 1,
        timesheetId: 11345,
        comment: "Fake comment from API",
        commentOn: "2020-01-22T22:57:44.3986373+00:00",
        commentById: 13,
        commentByName: "Dan White",
        commentByRole: role.TK
      }
    ],
    newComment: ""
    */
  },
  timeout: { lastActive: Date.now() }
};
