import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { getFullName } from "../../utils/personUtils";
import { totalHoursDollars } from "../../utils/timesheetUtils";

// Reducer for currentEdit.timecards
const timeCards = (state, action) => {
  switch (action.type) {
    case types.currentEdit.ADD_TIMECARDS:
      return [...state, ...action.newCards];
    case types.currentEdit.REMOVE_TIMECARD:
      return state.map(tc =>
        tc.timecardId === action.cardId
          ? { ...tc, metadata: { ...tc.metadata, deleted: true } }
          : tc
      );
    case types.currentEdit.UPDATE_TIMECARD_FIELD:
      return state.map(tc =>
        tc.timecardId === action.cardId
          ? {
              ...tc,
              [action.field]: action.value,
              metadata: { ...tc.metadata, isDirty: true }
            }
          : tc
      );
    case types.currentEdit.UPDATE_TIMECARD_HOURS:
      return state.map(tc =>
        tc.timecardId === action.cardId
          ? {
              ...tc,
              hours: (tc.hours || Array.from({ length: 14 })).map((h, i) =>
                i === action.index
                  ? { ...h, hours: action.value, isDirty: true }
                  : h
              )
              // , metadata: { ...tc.metadata, isDirty: true }
            }
          : tc
      );
    default:
      return state;
  }
};

// Reducer for currentEdit.earnings
const earnings = (state, action) => {
  switch (action.type) {
    case types.currentEdit.ADD_EARNINGS:
      return [...state, ...action.newCards];
    case types.currentEdit.REMOVE_EARNINGS:
      return state.map(card =>
        card.earningsCardId === action.cardId
          ? { ...card, metadata: { ...card.metadata, deleted: true } }
          : card
      );
    case types.currentEdit.UPDATE_EARNINGS_FIELD:
      return state.map(d =>
        d.earningsCardId === action.cardId
          ? {
              ...d,
              [action.field]: action.value,
              metadata: {
                ...d.metadata,
                isDirty: action.isDirty || d.metadata.isDirty
              }
            }
          : d
      );
    default:
      return state;
  }
};

// Reducer for currentEdit.timesheet
const timesheet = (state, action) => {
  switch (action.type) {
    case types.currentEdit.LOAD_TIMECARDS_AND_HOURS_SUCCESS:
      const hoursSub = totalHoursDollars(action.timecards);
      return {
        ...state,
        hoursSubtotal: hoursSub,
        tugTotal: ((state && state.earnings) || 0) + hoursSub
      };
    default:
      return state;
  }
};

export default function currentEditReducer(
  state = initialState.currentEdit,
  action
) {
  // console.log(action.type);
  switch (action.type) {
    case types.timesheet.LOAD_TIMESHEET_SUCCESS:
    case types.timesheet.UPDATE_TIMESHEET_STATUS_SUCCESS:
    case types.timesheet.UPDATE_TIMESHEET_SUCCESS:
      return { ...state, timesheet: action.sheet };
    case types.timesheet.UPDATE_TIMESHEET_HOURS_SUCCESS:
      return { ...state, timesheet: { ...action.sheet, hours: action.hours } };
    case types.currentEdit.LOAD_TIMECARDS_BEGIN:
    case types.currentEdit.LOAD_TIMECARDS_AND_HOURS_BEGIN:
      return state;
    case types.currentEdit.LOAD_TIMECARDS_SUCCESS:
    case types.currentEdit.LOAD_TIMECARDS_AND_HOURS_SUCCESS:
      return {
        ...state,
        timecards: action.timecards.map(tc => ({
          ...tc,
          paidRate: tc.paidRate ?? 0,
          employeeName: getFullName(
            tc.employeeFirstName,
            tc.employeeMiddleName,
            tc.employeeLastName,
            tc.employeeSuffix
          )
        })),
        timesheet: timesheet(state.timesheet, action)
      };
    case types.currentEdit.LOAD_EARNINGS_BEGIN:
      return state;
    case types.currentEdit.LOAD_EARNINGS_SUCCESS:
      return { ...state, earnings: action.earnings, hasChanges: false };
    case types.currentEdit.LOAD_COMMENTS_BEGIN:
      return state;
    case types.currentEdit.LOAD_COMMENTS_SUCCESS:
      return { ...state, comments: action.comments };
    case types.currentEdit.LOCK_TIMESHEET_BEGIN:
      return { ...state, isEditing: true };
    case types.currentEdit.LOCK_TIMESHEET_SUCCESS:
      return state;
    case types.currentEdit.UNLOCK_TIMESHEET_BEGIN:
      return state;
    case types.currentEdit.UNLOCK_TIMESHEET_SUCCESS:
      // Set isEditing to false so the TimesheetForm can try to reload if we're not leaving; the leaving flag there can prevent the reload
      return { isEditing: false, hasChanges: false };
    case types.currentEdit.CLEAR_CURRENT:
      return { isEditing: false, hasChanges: false };
    case types.currentEdit.SAVE_TIMECARDS_SUCCESS:
    case types.currentEdit.SAVE_HOURS_SUCCESS:
      return { ...state, hasChanges: false };
    case types.currentEdit.ADD_TIMECARDS:
    case types.currentEdit.REMOVE_TIMECARD:
    case types.currentEdit.UPDATE_TIMECARD_FIELD:
    case types.currentEdit.UPDATE_TIMECARD_HOURS:
      return {
        ...state,
        timecards: timeCards(state.timecards, action),
        hasChanges: true
      };
    case types.currentEdit.ADD_EARNINGS:
    case types.currentEdit.REMOVE_EARNINGS:
    case types.currentEdit.UPDATE_EARNINGS_FIELD:
      return {
        ...state,
        earnings: earnings(state.earnings, action),
        hasChanges: true
      };
    case types.currentEdit.SAVE_EARNINGS_SUCCESS:
      return { ...state, hasChanges: false };
    case types.currentEdit.UPDATE_HASCHANGES:
      return { ...state, hasChanges: action.hasChanges };
    case types.currentEdit.UPDATE_NEW_COMMENT:
      return { ...state, newComment: action.comment };
    default:
      return state;
  }
}
