/** Permission constants */
export const perm = {
  /** Ensure Data                           */ ED: "ED",
  /** Manage Pay Periods                    */ MPP: "MPP",
  /** Manage Users                          */ MU: "MU",
  /** Unlock Timesheet                      */ UT: "UT",
  /** Open Pay Period                       */ OPP: "OPP",
  /** Create Timesheet                      */ CT: "CT",
  /** Re-open Timesheet                     */ ROT: "ROT",
  /** Close Pay Period                      */ CPP: "CPP",
  /** Edit Timesheet for Closed Pay Periods */ ET: "ET",
  /** Process Timesheet - Generate CSV File */ CSV: "CSV",
  /** Add Employees to Timesheet            */ AE: "AE",
  /** Override Paid Position Value          */ OP: "OP",
  /** Override rate to change Rate Value    */ ORV: "ORV",
  /** Enter Employee Earnings Information   */ EET: "EET",
  /** Approves Timesheets                   */ AT: "AT",
  /** Enter Employee Hours Information      */ EEH: "EEH",
  /** Adjust and add employee rows if raises occur mid period */ AFLO: "AFLO",
  /** Validate Timesheet Amounts            */ VTA: "VTA",
  /** View All Comments                     */ VAC: "VAC",
  /** View Comments by Time Keeper          */ VCTK: "VCTK",
  /** View Comments by Payroll Processer    */ VCPP: "VCPP",
  /** View Comments by Payroll Admin        */ VCPA: "VCPA"
};

/** Role constants */
export const role = {
  /** Time Keeper           */ TK: "TK",
  /** Payroll Processer     */ PP: "PP",
  /** Payroll Administrator */ PA: "PA"
};

// // Role Checks (No longer being used; supersceded by permission checks)
// export const isTK = user => {
//   return user && user.roleCode === role.TK;
// };
// export const isPP = user => {
//   return user && user.roleCode === role.PP;
// };
// export const isPA = user => {
//   //   console.log(user);
//   return user && user.roleCode === role.PA;
// };

// export const isAtLeastPP = user => {
//   return isPP(user) || isPA(user);
// };
// export const isAtLeastTK = user => {
//   return isTK(user) || isPP(user) || isPA(user);
// };
// // END Role Checks

// Permission Checks
export const hasPerm = (user, permission) => {
  return (
    user &&
    user.permissions &&
    user.permissions.length > 0 &&
    user.permissions.includes(permission)
  );
};
// END Permission Checks
