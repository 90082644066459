import { lookups, health } from "../actions/actionTypes";
import initialState from "./initialState";
import { REGULAR } from "../../utils/utils";

export function earningTypesReducer(state = initialState.earningTypes, action) {
  switch (action.type) {
    case lookups.LOAD_EARNINGTYPES_SUCCESS:
      return action.earningTypes.sort((a, b) =>
        a.description === b.description
          ? 0
          : a.description < b.description
          ? -1
          : 1
      );
    default:
      return state;
  }
}

const sortHoursTypes = types => {
  return (types || []).sort((a, b) => {
    const aVal = a.description;
    const bVal = b.description;
    return aVal === bVal
      ? 0
      : aVal === REGULAR
      ? -1
      : bVal === REGULAR
      ? 1
      : aVal < bVal
      ? -1
      : 1;
  });
};
export function hoursTypesReducer(state = initialState.hoursTypes, action) {
  switch (action.type) {
    case lookups.LOAD_HOURSTYPES_SUCCESS:
      return sortHoursTypes(action.hoursTypes);
    default:
      return state;
  }
}

export function positionsReducer(state = initialState.positions, action) {
  switch (action.type) {
    case lookups.LOAD_POSITIONS_SUCCESS:
      return action.positions;
    default:
      return state;
  }
}

export function apiStatusReducer(state = initialState.apiStatus, action) {
  switch (action.type) {
    case health.LOAD_HEALTH_SUCCESS:
      return action.status;
    case health.LOAD_HEALTH_FAILURE:
      console.log("API health failure");
      return {
        status: "Service Unavailable",
        date: new Date(),
        version: "The API could not be reached"
      };
    default:
      return state;
  }
}

export function rolesReducer(state = initialState.roles, action) {
  switch (action.type) {
    case lookups.LOAD_ROLES_SUCCESS:
      return action.roles;
    default:
      return state;
  }
}
