import React from "react";
import { lookupDescr, REGULAR } from "../../utils/utils";

const Dropdown = ({ types, ...props }) => {
  const handleChange = evt => {
    const { id, value } = evt.target;
    const cardId = parseInt(id);
    if (cardId && props.onChange) {
      props.onChange(cardId, props.idColumn, value);
      // update description value in addition to ID
      props.onChange(cardId, props.textColumn, lookupDescr(types, value));
    }
  };

  const typesWithDefault = () => {
    if (
      props.selectedValue &&
      types.find(t => t.id.toString() === props.selectedValue.toString())
    )
      return types;
    else if (props.selectedText) {
      return [{ id: 0, description: props.selectedText }, ...types];
    } else return [{ id: 0, description: " - Please Select - " }, ...types];
  };

  return (
    <>
      {props.canEdit ? (
        <select
          id={props.id}
          value={props.selectedValue}
          onChange={handleChange}
          disabled={!props.canEdit}
        >
          {typesWithDefault().map(t => (
            <option
              key={t.id}
              value={t.id}
              className={t.description === REGULAR ? "top-choice" : ""}
            >
              {t.description}
            </option>
          ))}
        </select>
      ) : (
        props.selectedText
      )}
    </>
  );
};

export default Dropdown;
