import React from "react";
import "../../App.css";
import TimeSheetForm from "./TimeSheetForm";
import Header from "../common/Header";

function TimesheetPage(props) {
  const tsid = parseInt(props.match.params.tsid || -1, 10);
  return (
    <>
      <Header auth={props.auth} />
      <TimeSheetForm tsid={tsid} />
    </>
  );
}

export default TimesheetPage;
