import {
  handleResponse,
  handleError,
  getAuthHeader,
  handlePostResponse,
  handlePutResponse
} from "./apiUtils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT;
const userUrl = baseUrl + "/user";
const allUserUrl = baseUrl + "/user/all";

export function getUser() {
  return fetch(userUrl, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function getUsers() {
  return fetch(allUserUrl, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function addUser(user) {
  // console.log("add user", user);
  const newUser = getTypedUser(user);
  return fetch(userUrl, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify(newUser)
  })
    .then(handlePostResponse)
    .catch(handleError);
}

export function updateUser(user) {
  // console.log("update user", user);
  const newUser = getTypedUser(user);
  return fetch(`${userUrl}/${user.id}`, {
    method: "PUT",
    headers: getAuthHeader(),
    body: JSON.stringify(newUser)
  })
    .then(handlePutResponse)
    .catch(handleError);
}

// Helper
function getTypedUser(user) {
  let { isNew, ...result } = user;
  if (user) {
    if (user.roleId) {
      try {
        const roleInt = parseInt(user.roleId);
        result = { ...result, roleId: roleInt };
      } catch {
        result = { ...result, roleId: 0 };
      }
    }
  }
  return result;
}
