import React from "react";

const CheckNumberDropdown = props => {
  const handleChange = e => {
    if (props.onChange) props.onChange(e.target.value);
  };

  return props.canEdit ? (
    <select
      value={props.checkNum || "1"}
      onChange={handleChange}
      className="number"
      disabled={!props.canEdit}
    >
      <option>1</option>
      <option>2</option>
    </select>
  ) : (
    props.checkNum
  );
};

export default CheckNumberDropdown;
