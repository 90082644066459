import React from "react";

const ToastErrors = (errors, title) => {
  return (
    <>
      {title}
      <ul>
        {(errors || []).map((e, i) => (
          <li key={i}>{e}</li>
        ))}
      </ul>
    </>
  );
};

export default ToastErrors;
