import React from "react";
import { Redirect } from "react-router-dom";
import { config } from "../../tools/config";

const LoginPage = ({ auth, timeout }) => {
  // calls the login method in authentication service
  const login = () => auth.login();

  return (
    <div>
      {
        // calls the isAuthenticated method in authentication service
        auth.isAuthenticated() && <Redirect to="/home" />
      }

      {timeout && (
        <>
          <header className="App-header">
            <div title={`Version: ${config.env.REACT_APP_VERSION}`}>
              Mariner Time & Attendance
              {config.env.REACT_APP_CUSTOM_NODE_ENV &&
                ` (${config.env.REACT_APP_CUSTOM_NODE_ENV})`}
            </div>
            <div className="modal-container">
              <div className="modal timeout">
                <p>You have been logged out due to inactivity. </p>
                <p>
                  Please close this browser window to exit, or click below to
                  re-enter the system.
                </p>
                <button onClick={login}>Enter</button>
              </div>
            </div>
          </header>
        </>
      )}
      {!timeout && !auth.isAuthenticated() && login()}
    </div>
  );
};

export default LoginPage;
