import { combineReducers } from "redux";
import payPeriods from "./payPeriodReducer";
import timesheets from "./timesheetReducer";
import {
  earningTypesReducer as earningTypes,
  hoursTypesReducer as hoursTypes,
  positionsReducer as positions,
  apiStatusReducer as apiStatus,
  rolesReducer as roles
} from "./lookupReducers";
import people from "./personReducer";
import currentEdit from "./currentEditReducer";
import user, { usersReducer as users } from "./userReducer";
import divisions from "./divisionReducer";
import timeout from "./timeoutReducer";
import dataExport from "./exportReducer";

const rootReducer = combineReducers({
  payPeriods,
  timesheets,
  earningTypes,
  hoursTypes,
  positions,
  people,
  currentEdit,
  user,
  users,
  divisions,
  dataExport,
  timeout,
  apiStatus,
  roles
});

export default rootReducer;
