import React, { useState } from "react";
import ListUsers from "./ListUsers";
import EditUser from "./EditUser";
import { toast } from "react-toastify";
// import UserDivisions from "./UserDivisions";

const UserMgmtPage = () => {
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [editing, setEditing] = useState(false);

  const handleUserClick = id => {
    if (editing)
      toast.info(
        "Save or Cancel the current user before selecting another user."
      );
    else setSelectedUserId(id);
  };

  return (
    <>
      <div className="user-container">
        <ListUsers
          selectedUserId={selectedUserId}
          setSelectedUserId={handleUserClick}
        />
        <EditUser
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          editing={editing}
          setEditing={setEditing}
        />
        {/* <UserDivisions selectedUserId={selectedUserId} /> */}
      </div>
    </>
  );
};

export default UserMgmtPage;
