import React, { useState, useEffect } from "react";
import { loadUsers } from "../../../redux/actions/userActions";
import { connect } from "react-redux";

const ListUsers = ({
  selectedUserId,
  setSelectedUserId,
  loadUsers,
  users,
  needToLoad
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (needToLoad) loadUsers();
  }, [loadUsers, needToLoad]);

  const handleSearchChange = evt => {
    setSearch(evt.target.value);
  };
  const handleUserClick = uid => {
    if (uid === selectedUserId) setSelectedUserId(0);
    else setSelectedUserId(uid);
  };

  const filteredSortedUsers = () => {
    // console.log(users);
    return users.allIds
      .map(id => users.byId[id])
      .filter(
        u =>
          u.id === selectedUserId ||
          u.fullName.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) =>
        a.fullName === b.fullName ? 0 : a.fullName > b.fullName ? 1 : -1
      );
  };

  return (
    <div className="user-list-container">
      <h2>Users: ({users.allIds.length})</h2>
      <input
        type="search"
        className="search"
        placeholder="Filter Users"
        value={search}
        onChange={handleSearchChange}
      />
      <div>
        {search
          ? `Showing ${filteredSortedUsers().length} of ${
              users.allIds.length
            } users.`
          : ""}
      </div>
      {/* <div className="user-list-results"> */}
      <div className="user-list">
        {filteredSortedUsers().map((u, i) => (
          <div
            key={i}
            className={
              "user-list-name" + (selectedUserId === u.id ? " active" : "")
            }
            onClick={() => handleUserClick(u.id)}
          >
            {u.fullName}
          </div>
        ))}
        {filteredSortedUsers().length === 0 && (
          <div className="user-list-no-results">No results found</div>
        )}
      </div>
      {selectedUserId ? (
        <div className="user-list-message">
          Click the selected name again to unselect.
        </div>
      ) : null}
      {/* </div> */}
    </div>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    users: state.users,
    needToLoad: state.users && state.users.needToLoad
  };
}
const mapDispatchToProps = { loadUsers };

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
