import { timesheet, payPeriod } from "./actionTypes";
import * as timesheetApi from "../../api/timesheetApi";
import { totalHours } from "../../utils/timesheetUtils";

export function loadTimesheets() {
  // thunk middleware needs this signature of function
  return async function (dispatch) {
    dispatch({ type: timesheet.LOAD_TIMESHEETS_START });
    return timesheetApi
      .getTimesheets()
      .then(timesheets => {
        dispatch({ type: timesheet.LOAD_TIMESHEETS_SUCCESS, timesheets });
      })
      .catch(error => {
        dispatch({ type: timesheet.LOAD_TIMESHEETS_FAILURE, error });
        throw error;
      });
  };
}

export function loadTimesheet(tsid) {
  return async function (dispatch) {
    return timesheetApi.getTimesheet(tsid).then(sheet => {
      // console.log("dispatching new timesheet", sheet);
      dispatch({ type: timesheet.LOAD_TIMESHEET_SUCCESS, sheet });
    });
  };
}

export function updateTimesheetStatus(status) {
  return async function (dispatch, getState) {
    dispatch({ type: timesheet.UPDATE_TIMESHEET_STATUS_START });
    const sheet = getState().currentEdit.timesheet;
    const newSheet = { ...sheet, status };
    return timesheetApi.updateTimesheet(newSheet).then(resp => {
      dispatch({
        type: timesheet.UPDATE_TIMESHEET_STATUS_SUCCESS,
        resp,
        sheet: newSheet
      });
      dispatch({ type: payPeriod.INVALIDATE_PAYPERIODS });
    });
  };
}

export function updateTimesheetHours() {
  // console.log("update hours", hours);
  // Need to pass in the sheet (or get it first) and save to DB first; otherwise, after updating hours in the store it reloads from the DB and overwrites the hours.
  return async (dispatch, getState) => {
    dispatch({ type: timesheet.UPDATE_TIMESHEET_HOURS_START });
    const cards = getState().currentEdit.timecards;
    const hours = totalHours(cards);
    const sheet = getState().currentEdit.timesheet;
    // console.log("update hours to", hours);
    return timesheetApi.updateTimesheet({ ...sheet, hours }).then(() => {
      dispatch({
        type: timesheet.UPDATE_TIMESHEET_HOURS_SUCCESS,
        sheet,
        hours
      });
    });
  };
}

export function updateTimesheet(sheet) {
  return dispatch => {
    dispatch({ type: timesheet.UPDATE_TIMESHEET_START });
    const newSheet =
      sheet && sheet.status === "New"
        ? { ...sheet, status: "Draft" }
        : { ...sheet };
    return timesheetApi.updateTimesheet(newSheet).then(() => {
      dispatch({
        type: timesheet.UPDATE_TIMESHEET_SUCCESS,
        sheet: newSheet
      });
    });
  };
}

export function addTimesheet(sheet) {
  return async function (dispatch) {
    return timesheetApi.addTimesheet(sheet).then(response => {
      dispatch({ type: timesheet.ADD_TIMESHEET_SUCCESS, response });
    });
  };
}

export function invalidateTimesheets() {
  return dispatch => {
    dispatch({ type: timesheet.INVALIDATE_TIMESHEETS });
  };
}
