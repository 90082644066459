import { user, division } from "../actions/actionTypes";
import initialState from "./initialState";

const objectsToFields = (objects, field) => (objects || []).map(o => o[field]);

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case user.LOAD_USER_SUCCESS:
      return {
        ...action.user,
        divisions: objectsToFields(action.user.divisions, "id"),
        permissions: objectsToFields(action.user.permissions, "code")
      };
    case user.LOAD_USER_FAILURE:
      return { ...state, needToLoad: false, loadFailed: true, error: action.error };
    case user.SET_USER_ROLE_START:
      return { ...state, roleCode: action.role };
    case user.SET_USER_NAME_START:
      return { ...state, fullName: action.name };
    default:
      return state;
  }
}

export function usersReducer(state = initialState.users, action) {
  switch (action.type) {
    case user.LOAD_USERS_START:
      return { ...state, needToLoad: true };
    case user.LOAD_USERS_SUCCESS:
      // console.log(action.users);
      return {
        byId: action.users.reduce(
          (collector, user) => ({
            ...collector,
            [user.id]: user
          }),
          []
        ),
        allIds: action.users.map(u => u.id),
        needToLoad: false
      };
    case user.LOAD_USERS_FAILURE:
      return initialState.users;
    case user.UPDATE_USER_SUCCESS:
    case user.ADD_USER_SUCCESS:
      // return { ...state, needToLoad: true };
      return initialState.users;
    case division.LOAD_USER_DIVISIONS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.userId]: {
            ...state.byId[action.userId],
            divisions: action.userDivs
          }
        }
      };
    case division.ADD_USER_DIVISION_SUCCESS:
    case division.DELETE_USER_DIVISION_SUCCESS:
    default:
      return state;
  }
}
