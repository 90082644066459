import {
  handleResponse,
  handleError,
  handlePutResponse,
  getAuthHeader
} from "./apiUtils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT;
const payPeriodUrl = baseUrl + "/payperiod";

export function getPayPeriods() {
  return fetch(payPeriodUrl, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function updatePayPeriodStatus(payPeriod, status) {
  // TODO: validate inputs
  return fetch(`${payPeriodUrl}/${payPeriod.id}`, {
    method: "PUT",
    headers: getAuthHeader(),
    body: JSON.stringify({ ...payPeriod, status })
  }).then(handlePutResponse);
}

export async function openPayPeriod(payPeriod) {
  return fetch(`${payPeriodUrl}/${payPeriod.id}/open`, {
    method: "PUT",
    headers: getAuthHeader(),
    body: JSON.stringify(payPeriod)
  }).then(handlePutResponse);
}

export async function closePayPeriod(payPeriod) {
  return fetch(`${payPeriodUrl}/${payPeriod.id}/close`, {
    method: "PUT",
    headers: getAuthHeader(),
    body: JSON.stringify(payPeriod)
  }).then(handlePutResponse);
}

export async function processPayPeriod(payPeriod) {
  return fetch(`${payPeriodUrl}/${payPeriod.id}/process`, {
    method: "PUT",
    headers: getAuthHeader(),
    body: JSON.stringify(payPeriod)
  }).then(handlePutResponse);
}
