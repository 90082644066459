import React from "react";
import "../../App.css";
import ModalHeader from "./ModalHeader";

const Modal = props => {
  if (props.isOpen === false) return null;
  return (
    <div>
      <div className="modal-container">
        <div className={"modal " + (props.modalClass || "")}>
          {!props.hideHeader && (
            <ModalHeader title={props.title} handleClose={close} />
          )}
          {props.children}
        </div>
      </div>
      <div
        className={"modal-backdrop " + (props.modalClass || "")}
        onClick={e => close(e)}
      ></div>
    </div>
  );

  function close(e) {
    e.preventDefault();
    if (props.onClose) {
      props.onClose();
    }
  }
};

export default Modal;
