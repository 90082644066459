import { payPeriod as types, timesheet } from "../actions/actionTypes";
import initialState from "./initialState";

export default function payPeriodReducer(
  state = initialState.payPeriods,
  action
) {
  switch (action.type) {
    case types.GET_PAYPERIODS_START:
      return state;
    case types.GET_PAYPERIODS_SUCCESS:
      if (!action.periods) return state;
      return {
        byId: action.periods.reduce((collector, period) => {
          return { ...collector, [period.id]: period };
        }, {}),
        allIds: action.periods.map(p => p.id),
        needToLoad: false
      };
    case types.OPEN_PAYPERIOD_START:
      return state;
    case types.OPEN_PAYPERIOD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payPeriod.id]: { ...action.payPeriod, status: "Opened" }
        }
      };
    case types.CLOSE_PAYPERIOD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payPeriod.id]: { ...action.payPeriod, status: "Closed" }
        }
      };
    case types.PROCESS_PAYPERIOD_START:
      return state;
    case types.PROCESS_PAYPERIOD_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payPeriod.id]: { ...action.payPeriod, status: "Processed" }
        }
      };
    case timesheet.ADD_TIMESHEET_SUCCESS:
    case types.INVALIDATE_PAYPERIODS:
      return { ...state, needToLoad: true };
    default:
      return state;
  }
}
