import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  formatDollars,
  formatNumber,
  formatDate,
  formatDateRange,
  filteredColumnOptions,
  filterOnColumns,
  handleFilter
} from "../../utils/utils";
import { hasPerm, perm } from "../../utils/userUtils";
import Header from "../common/Header";
import Spinner from "../common/Spinner";
import ColumnFilter from "../common/ColumnFilter";
import Modal from "../common/Modal";
import PayPeriodMgmtPage from "../management/PayPeriodMgmtPage";
import TimesheetMgmtPage from "../management/TimesheetMgmtPage";
import {
  loadTimesheets,
  invalidateTimesheets
} from "../../redux/actions/timesheetActions";
import { clearCurrentEdit } from "../../redux/actions/currentEditActions";
import Error from "../common/Error";
import UserMgmtPage from "../management/user/UserMgmtPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";

const HomePage = ({
  timesheets,
  needToLoad,
  isFetching,
  loadTimesheets,
  invalidateTimesheets,
  user,
  currentTimesheetId,
  clearCurrentEdit,
  ...props
}) => {
  useEffect(() => {
    // console.log("in HomePage useEffect() load timesheets", needToLoad);
    if (needToLoad) {
      setErrorMessage("");
      setErrorHeading(null);
      if (user && user.fullName) {
        loadTimesheets().catch(error => {
          console.error("Loading timesheets failed. " + error);
          setErrorMessage(
            "The timesheets could not be loaded for user " + user.fullName
          );
        });
      }
    }
  }, [loadTimesheets, needToLoad, user]);

  useEffect(() => {
    if (currentTimesheetId > 0) {
      console.log("home clear");
      clearCurrentEdit(user.id);
    }
  }, [currentTimesheetId, clearCurrentEdit, user.id]);

  useEffect(() => {
    setColumnFilters(filters => ({
      ...filters,
      Status: [...new Set((timesheets || []).map(t => t.status))].filter(s =>
        ["New", "Draft", "Approved"].includes(s)
      )
    }));
  }, [timesheets]);

  const [isTsDialogOpen, setIsTsDialogOpen] = useState(false);
  const [isPpDialogOpen, setIsPpDialogOpen] = useState(false);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorHeading, setErrorHeading] = useState();

  // Map of display names to column names for columns with sort and filters
  const sortableColumns = {
    Division: "division",
    Asset: "asset",
    "Pay Period": "payPeriodStart",
    Status: "status"
  };

  // Modal events //
  const openTsModal = () => {
    setIsTsDialogOpen(true);
  };
  const openPpModal = () => {
    setIsPpDialogOpen(true);
  };
  const openUserModal = () => {
    setIsUserDialogOpen(true);
  };
  const closeModal = () => {
    invalidateTimesheets();
    setIsTsDialogOpen(false);
    setIsPpDialogOpen(false);
    setIsUserDialogOpen(false);
  };

  // I don't like the repetition of sort/filter state and event handlers on each page that needs them, but not sure of the better way to do it yet
  const [columnSort, setColumnSort] = useState({
    direction: "Asc",
    column: ""
  });
  const [columnFilters, setColumnFilters] = useState({
    "Pay Period": [],
    Asset: [],
    Division: [],
    Status: []
  });

  // Sorting and Filtering events //
  const handleSort = (direction, column) => {
    // console.log("Sort: ", column, direction);
    // Clicking the same sort a second time clears the sort
    if (direction === columnSort.direction && column === columnSort.column) {
      setColumnSort({ direction: "", column });
    } else {
      setColumnSort({ direction, column });
    }
  };

  const handleFilterLocal = (value, column) => {
    // Toggle whether the value is included in the set of filter options for this column.
    // console.log("filter: ", column, value);
    const visibleOptions =
      column === "Pay Period" ? dateOptions(column) : stringOptions(column);
    handleFilter(
      value,
      column,
      columnFilters,
      setColumnFilters,
      visibleOptions
    );
  };

  const handleFilterAll = (selectAll, column) => {
    if (selectAll) {
      setColumnFilters({
        ...columnFilters,
        [column]:
          column === "Pay Period" ? dateOptions(column) : stringOptions(column)
      });
    } else {
      setColumnFilters({ ...columnFilters, [column]: [] });
    }
  };

  const filteredSortedData = data => {
    return filterOnColumns(data, sortableColumns, columnFilters).sort(
      (a, b) => {
        let aVal = a[sortableColumns[columnSort.column]];
        let bVal = b[sortableColumns[columnSort.column]];
        // Default sort by 2 columns
        if (!columnSort.column) {
          aVal = a.division + ";#" + a.asset;
          bVal = b.division + ";#" + b.asset;
        }
        return aVal === bVal
          ? 0
          : aVal < bVal
          ? columnSort.direction === "Asc"
            ? -1
            : 1
          : columnSort.direction === "Asc"
          ? 1
          : -1;
      }
    );
  };
  /////////////// end code to generalize - with Redux?? ///////////////////

  const dateOptions = column => {
    const options = stringOptions(column);
    return options.reverse().map(o => formatDate(o));
  };
  const stringOptions = column => {
    return filteredColumnOptions(
      timesheets,
      sortableColumns,
      columnFilters,
      column
    );
  };

  return (
    <>
      <Header auth={props.auth} />
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <div className="page-header">
            <div className="page-heading">Timesheet Summary</div>
            <div className="page-buttons">
              {hasPerm(user, perm.MU) && (
                <button type="button" onClick={openUserModal}>
                  User Management
                </button>
              )}
              {hasPerm(user, perm.CT) && (
                <button type="button" onClick={openTsModal}>
                  Timesheet Management
                </button>
              )}
              {hasPerm(user, perm.MPP) && (
                <button type="button" onClick={openPpModal}>
                  Pay Period Management
                </button>
              )}
            </div>
          </div>
          <div className="App-content">
            <div>
              <div className="timesheet-container">
                {errorMessage || errorHeading ? (
                  <Error message={errorMessage} heading={errorHeading} />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th className="col-one-half">ID</th>
                        <th>
                          <ColumnFilter
                            label="Pay Period"
                            options={dateOptions("Pay Period")}
                            selectedOptions={columnFilters["Pay Period"]}
                            sortCallback={handleSort}
                            checkCallback={handleFilterLocal}
                            onCheckAll={handleFilterAll}
                            showFilterIcon={true}
                            showSort={true}
                          />
                        </th>
                        <th>
                          <ColumnFilter
                            label="Asset"
                            options={stringOptions("Asset")}
                            selectedOptions={columnFilters["Asset"]}
                            sortCallback={handleSort}
                            checkCallback={handleFilterLocal}
                            onCheckAll={handleFilterAll}
                            showFilterIcon={true}
                            showSort={true}
                          />
                        </th>
                        <th>
                          <ColumnFilter
                            label="Division"
                            options={stringOptions("Division")}
                            selectedOptions={columnFilters["Division"]}
                            sortCallback={handleSort}
                            checkCallback={handleFilterLocal}
                            onCheckAll={handleFilterAll}
                            showFilterIcon={true}
                            showSort={true}
                          />
                        </th>
                        <th>
                          <ColumnFilter
                            label="Status"
                            options={stringOptions("Status")}
                            selectedOptions={columnFilters["Status"]}
                            sortCallback={handleSort}
                            checkCallback={handleFilterLocal}
                            onCheckAll={handleFilterAll}
                            showFilterIcon={true}
                            showSort={true}
                          />
                        </th>
                        <th>Hours</th>
                        <th>Hours x Rate</th>
                        <th>Earnings</th>
                        <th>Total Tug $'s</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSortedData(timesheets).map((t, i) => (
                        <tr
                          key={t.id}
                          className={
                            i % 2 === 1 ? "normal-bkg" : "alternate-bkg"
                          }
                        >
                          <td>
                            <NavLink to={`/timesheet/${t.id}`}>
                              {t.id}
                              {t.lockedBy && hasPerm(user, perm.UT) ? (
                                <span
                                  title={`Timesheet locked by ${t.lockedByName}`}
                                  className="timesheet-locked"
                                >
                                  <FontAwesomeIcon icon={faUserLock} />
                                </span>
                              ) : (
                                ""
                              )}
                            </NavLink>
                          </td>
                          <td>
                            {formatDateRange(t.payPeriodStart, t.payPeriodEnd)}
                          </td>
                          <td>{t.asset}</td>
                          <td>{t.division}</td>
                          <td>{t.status}</td>
                          <td className="number">{t.hours}</td>
                          <td className="number">
                            {hasPerm(user, perm.EET) &&
                              "$" + formatDollars(t.hoursSubtotal)}
                          </td>
                          <td className="number">
                            {hasPerm(user, perm.EET) &&
                            (t.earnings || t.earnings === 0)
                              ? "$" + formatDollars(t.earnings)
                              : ""}
                          </td>
                          <td className="number">
                            {hasPerm(user, perm.EET) &&
                              "$" + formatDollars(t.tugTotal)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="5" className="timesheet-summary">
                          Totals:
                        </td>
                        <td className="hours totals">
                          {formatNumber(
                            filteredSortedData(timesheets).reduce(
                              (sum, t) => (sum += t.hours || 0),
                              0
                            ),
                            true
                          ) || "0.00"}
                        </td>
                        {hasPerm(user, perm.EET) ? (
                          <>
                            <td className="hours totals">
                              $
                              {formatDollars(
                                filteredSortedData(timesheets).reduce(
                                  (sum, t) => (sum += t.hoursSubtotal || 0),
                                  0
                                )
                              ) || "0.0000"}
                            </td>
                            <td className="hours totals">
                              $
                              {formatDollars(
                                filteredSortedData(timesheets).reduce(
                                  (sum, t) => (sum += t.earnings || 0),
                                  0
                                )
                              ) || "0.0000"}
                            </td>
                            <td className="hours totals">
                              $
                              {formatDollars(
                                filteredSortedData(timesheets).reduce(
                                  (sum, t) => (sum += t.tugTotal || 0),
                                  0
                                )
                              ) || "0.0000"}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="totals"></td>
                            <td className="totals"></td>
                            <td className="totals"></td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <Modal
              isOpen={isUserDialogOpen}
              onClose={closeModal}
              modalClass="collapse-x"
              title="User Management"
            >
              <UserMgmtPage onClose={closeModal}></UserMgmtPage>
            </Modal>
            <Modal
              isOpen={isTsDialogOpen}
              onClose={closeModal}
              modalClass="collapse-x"
              title="Timesheet Management"
            >
              <TimesheetMgmtPage onClose={closeModal}></TimesheetMgmtPage>
            </Modal>
            <Modal
              isOpen={isPpDialogOpen}
              onClose={closeModal}
              modalClass="collapse"
              title="Pay Period Management"
            >
              <PayPeriodMgmtPage onClose={closeModal}></PayPeriodMgmtPage>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    timesheets: state.timesheets.allIds.map(id => state.timesheets.byId[id]),
    needToLoad: state.timesheets.needToLoad,
    isFetching: state.timesheets.isFetching,
    user: state.user,
    currentTimesheetId:
      state.currentEdit && state.currentEdit.timesheet
        ? state.currentEdit.timesheet.id
        : 0
  };
}
const mapDispatchToProps = {
  loadTimesheets,
  invalidateTimesheets,
  clearCurrentEdit
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
