import React from "react";

const TimesheetDuplicates = ({ duplicates, ...props }) => {
  return (
    <div className="modal-content">
      <h2>The following have Duplicate Entries</h2>
      <p>
        You must remove or change the following duplicate rows <br />
        before you can Approve this timesheet.
      </p>
      {duplicates && duplicates.positions && duplicates.positions.length ? (
        <>
          <h3>Position &amp; Pay</h3>
          <ul>
            {duplicates.positions.map((tc, i) => (
              <li key={i}>
                {tc.moranId}: {tc.employeeName} / {tc.hoursType}
              </li>
            ))}
          </ul>
        </>
      ) : null}
      {duplicates && duplicates.earnings && duplicates.earnings.length ? (
        <>
          <h3>Earnings</h3>
          <div>
            <ul>
              {duplicates.earnings.map((ec, i) => (
                <li key={i}>
                  {ec.moranId}: {ec.employeeName} / {ec.earningType}
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default TimesheetDuplicates;
