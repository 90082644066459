import { division } from "../actions/actionTypes";
import initialState from "./initialState";

export default function divisionsReducer(
  state = initialState.divisions,
  action
) {
  switch (action.type) {
    case division.LOAD_DIVISIONS_SUCCESS:
      //   return action.divisions;
      return {
        byId: action.divisions
          .sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1))
          .reduce((collector, div) => ({ ...collector, [div.id]: div }), []),
        allIds: action.divisions.map(d => d.id),
        needToLoad: false
      };
    default:
      return state;
  }
}
