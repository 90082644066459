import { handleResponse, handleError, getAuthHeader } from "./apiUtils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT + "/person";

export function getPeople() {
  return fetch(baseUrl, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

// Get a person with their rate for the specific pay period
export function getPerson(id, payPeriodId) {
  return fetch(`${baseUrl}/${id}/${payPeriodId}`, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}
