import React from "react";
import Checkbox from "../../common/Checkbox";

const UserDivision = ({
  selectedUserId,
  divisions,
  userDivisions,
  setUserDivisions,
  canEdit
}) => {
  const divList = () => {
    // console.log(divisions);
    return divisions && divisions.byId && divisions.allIds
      ? divisions.allIds.map(id => divisions.byId[id])
      : [];
  };

  const handleCheckAll = () => {
    if (!canEdit) return;
    if (divList().length === userDivisions.length) setUserDivisions([]);
    else setUserDivisions([...divList()]);
  };
  const handleCheck = evt => {
    if (!canEdit) return;
    const divId = parseInt(evt.target.value);
    // console.log(divId, typeof divId);
    if (userDivisions.map(ud => ud.id).includes(divId))
      setUserDivisions(u => u.filter(ud => ud.id !== divId));
    else setUserDivisions(u => [...u, divisions.byId[divId]]);
  };

  return (
    <>
      {(selectedUserId > 0 || canEdit) && (
        <div className="user-edit-divisions">
          <h3>User Divisions</h3>
          <div>
            {userDivisions.length} of {divList().length} selected
          </div>
          <div className="user-division-list">
            <div className="select-all">
              <Checkbox
                label="(Select All)"
                value="ALL"
                isSelected={divList().length === userDivisions.length}
                onCheckChange={handleCheckAll}
                disabled={!canEdit}
              />
            </div>
            {divList().map(d => (
              <div key={d.id}>
                <Checkbox
                  label={d.name}
                  value={d.id}
                  isSelected={userDivisions.map(ud => ud.id).includes(d.id)}
                  onCheckChange={handleCheck}
                  disabled={!canEdit}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDivision;
