import {
  handleResponse,
  handlePostResponse,
  handleError,
  getAuthHeader
} from "./apiUtils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT;

export function getPayPeriodExport(payPeriodId) {
  return fetch(`${baseUrl}/export/${payPeriodId}`, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function createPayPeriodExport(payPeriodId) {
  return fetch(`${baseUrl}/export/${payPeriodId}`, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify({ payPeriodId })
  })
    .then(handlePostResponse)
    .catch(handleError);
}
