import React from "react";
import "./Spinner.css";

const Spinner = props => {
  return (
    <div
      className={"loader" + (props.extraClass ? " " + props.extraClass : "")}
    >
      Loading...
    </div>
  );
};

export default Spinner;
