import { handleResponse, handleError, getAuthHeader } from "./apiUtils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT;

export function getEarningTypes() {
  return fetch(baseUrl + "/earningtype", { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function getHoursTypes() {
  return fetch(baseUrl + "/hourstype", { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function getPositions() {
  return fetch(baseUrl + "/position", { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function getRoles() {
  return fetch(baseUrl + "/role", { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}
