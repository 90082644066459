import React from "react";
import { Redirect } from "react-router-dom";
import AuthService from "./authService";
import { connect } from "react-redux";
import { loadUser } from "../redux/actions/userActions";
import Error from "../Components/common/Error";
import Header from "../Components/common/Header";

const auth = new AuthService();

export default function Authorized(WrappedComponent) {
  const AuthorizedComponent = ({ loadUser, ...props }) => {
    if (auth.isAuthenticated()) {
      // Verify User info is loaded.
      if (!props.user || props.user.needToLoad) {
        loadUser();
        return <></>;
      } else if (props.user.loadFailed) {
        // console.log(props.user);
        return (
          <>
            <Header auth={auth} />
            <div className="page-header">
              <div className="page-heading">Error</div>
            </div>
            <div className="App-content">
              <Error
                heading="Access denied, user not found."
                message="Please verify the user account you are logging in with exists in the database."
              />
            </div>
          </>
        );
      }
      // else console.log(props.user);
      else return <WrappedComponent auth={auth} {...props} />;
    } else {
      return <Redirect to="/" />;
    }
  };
  AuthorizedComponent.displayName = `Authorized(${getDisplayName(
    WrappedComponent
  )})`;
  function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
  }

  // Redux
  function mapStateToProps(state) {
    return { user: state.user };
  }
  const mapDispatchToProps = { loadUser };

  return connect(mapStateToProps, mapDispatchToProps)(AuthorizedComponent);
}
