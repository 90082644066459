import {
  handleResponse,
  handleError,
  getAuthHeader,
  handlePostResponse,
  handleDeleteResponse
} from "./apiUtils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT;
const divisionUrl = baseUrl + "/division";

export function getDivisions() {
  return fetch(`${divisionUrl}/all`, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserDivisions(userId) {
  return fetch(`${divisionUrl}/${userId}`, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function addUserDivisions(userId, divisions) {
  return fetch(`${divisionUrl}/${userId}`, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify(divisions)
  })
    .then(handlePostResponse)
    .catch(handleError);
}

export function deleteUserDivisions(userId, divisions) {
  return fetch(`${divisionUrl}/${userId}`, {
    method: "DELETE",
    headers: getAuthHeader(),
    body: JSON.stringify(divisions)
  })
    .then(handleDeleteResponse)
    .catch(handleError);
}
