import { division } from "./actionTypes";
import * as divisionApi from "../../api/divisionApi";

export function loadDivisions() {
  return async function (dispatch) {
    return divisionApi
      .getDivisions()
      .then(divisions => {
        dispatch({ type: division.LOAD_DIVISIONS_SUCCESS, divisions });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadUserDivisions(userId) {
  return async function (dispatch) {
    return divisionApi
      .getUserDivisions(userId)
      .then(userDivs => {
        dispatch({
          type: division.LOAD_USER_DIVISIONS_SUCCESS,
          userDivs,
          userId
        });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function addUserDivisions(userId, divisions) {
  // console.log("add", divisions);
  return async function (dispatch) {
    return divisionApi
      .addUserDivisions(userId, divisions)
      .then(() => dispatch({ type: division.ADD_USER_DIVISION_SUCCESS }))
      .catch(error => {
        throw error;
      });
  };
}

export function deleteUserDivisions(userId, divisions) {
  return async function (dispatch) {
    return divisionApi
      .deleteUserDivisions(userId, divisions)
      .then(() => dispatch({ type: division.DELETE_USER_DIVISION_SUCCESS }))
      .catch(error => {
        throw error;
      });
  };
}
