import { formatDollars, SHORESIDE_CHECK } from "./utils";

export const getNextCardId = (cards, idColumn) => {
  return Math.min(...cards.map(c => c[idColumn]), 0) - 1;
};

const getDefaultHours = (timecardId, start) => {
  const startDate = new Date(start);
  // const id = timecard.timecardId;
  return Array.from({ length: 14 }).map((h, i) => {
    let newDate = new Date(startDate);
    newDate.setDate(newDate.getDate() + i);
    return {
      id: 0,
      timecardId: timecardId,
      workedOn: newDate, //.toISOString().slice(0, 19),
      hours: null,
      isDirty: true,
      isNew: true
    };
  });
};

export function getEarningsCardsToAdd(
  allPeople,
  peopleIds,
  rowTypes,
  tsid,
  startCardId,
  companyId
) {
  const peopleCards = getPeopleForEarnings(allPeople, peopleIds, tsid, companyId);
  // console.log("peopleCards", peopleCards);
  const newCards = rowTypes.reduce((cards, rowType) => {
    const oneTypeCards = peopleCards.map((c, i) => {
      const nextCardId =
        Math.min(startCardId, getNextCardId(cards, "earningsCardId")) - i;
      return {
        ...c,
        earningType: rowType.description,
        earningTypeId: rowType.id,
        earningsCardId: nextCardId
      };
    });
    return [...cards, ...oneTypeCards];
  }, []);
  //   console.log(newCards);
  return newCards;
}

export const getPeopleForEarnings = (allPeople, peopleIds, timesheetId, companyId) => {
  return peopleIds
    .map(id => allPeople.byId[id])
    .map(person => ({
      timesheetId,
      employeeId: person.id,
      moranId: person.moranId,
      employeeName: getFullName(
        person.firstName,
        person.middleName,
        person.lastName,
        person.nameSuffix
      ),
      employeeLastName: person.lastName,
      checkNum: (person.isShoreside && companyId !== 3) ? SHORESIDE_CHECK : "1",
      metadata: { isDirty: true, deleted: false, isNew: true },
      position: person.defaultJob,
      positionId: person.positionId,
      earningTypeId: 0,
      earningType: "",
      amount: 0.0
    }));
};

export const getTimeCardsToAdd = (
  rowTypes,
  peopleCards,
  startCardId,
  payPeriodStart
) => {
  const newCards = rowTypes.reduce((cards, rowType) => {
    const oneTypeCards = peopleCards.map((c, i) => {
      const nextCardId =
        Math.min(startCardId, getNextCardId(cards, "timecardId")) - i;
      return {
        ...c,
        hoursType: rowType.description,
        hoursTypeId: rowType.id,
        timecardId: nextCardId,
        hours: getDefaultHours(nextCardId, payPeriodStart)
      };
    });
    return [...cards, ...oneTypeCards];
  }, []);
  //   console.log(newCards);
  return newCards;
};

export const makeDataCardFromPerson = (person, timesheetId, companyId) => {
  let result = {
    timesheetId,
    employeeId: person.id,
    moranId: person.moranId,
    employeeName: getFullName(
      person.firstName,
      person.middleName,
      person.lastName,
      person.nameSuffix
    ),
    employeeLastName: person.lastName,
    defaultRateId: person.defaultRateId,
    metadata: { isDirty: true, deleted: false, isNew: true },
    hoursTypeId: 0,
    hoursType: "",
    division: person.division,
    divisionId: person.divisionId
  };
  // Shoreside person gets different defaults
  if (person.isShoreside)
    result = {
      ...result,
      checkNum: (companyId !== 3) ? SHORESIDE_CHECK : "1",
      defaultJob: person.defaultJob,
      defaultRate: 0.0,
      overrideRate: true,
      position: "",
      positionId: 0,
      paidRate: formatDollars(0, false),
      isShoreside: true
    };
  else
    result = {
      ...result,
      checkNum: "1",
      defaultJob: person.defaultJob,
      defaultRate: person.defaultRate,
      overrideRate: false,
      position: person.defaultJob,
      positionId: person.positionId,
      paidRate: formatDollars(person.defaultRate || 0, false),
      isShoreside: false
    };
  return result;
};

export const getFullName = (...nameParts) => {
  return nameParts
    .reduce(
      (name, part) =>
        part && part.trim() ? (name += part.trim() + " ") : name,
      ""
    )
    .trim();
};
