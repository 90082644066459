import { timeout } from "./actionTypes";
// import { clearCurrentEdit } from "./currentEditActions";

export function resetTimeout() {
  // thunk middleware needs this signature of function
  return async function(dispatch) {
    dispatch({ type: timeout.RESET_TIMEOUT });
  };
}

// export function cleanupOnTimeout(personId) {
//   return async function(dispatch) {
//     return dispatch(clearCurrentEdit(personId));
//   };
// }
