import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import TimeSheetPositionPay from "./TimeSheetPositionPay";
import TimeSheetHours from "./TimeSheetHours";
import TimeSheetEarnings from "./TimeSheetEarnings";
import "react-tabs/style/react-tabs.css";
import "../../App.css";
import {
  formatDollars,
  formatNumber,
  lookupType,
  viewableCards,
  viewableEarnings
} from "../../utils/utils";
import { totalHours, totalEarnings } from "../../utils/timesheetUtils";
import TimeSheetInfo from "./TimeSheetInfo";
import TimesheetComments from "./TimesheetComments";
import { withRouter } from "react-router-dom";
import { getEarningsCardsToAdd, getNextCardId } from "../../utils/personUtils";
import { hasPerm, perm } from "../../utils/userUtils";
import { resetTimeout } from "../../redux/actions/timeoutActions";
import { updateTimesheetStatus } from "../../redux/actions/timesheetActions";
import {
  loadTimesheetDeep,
  unlockTimesheet,
  clearCurrentEdit,
  updateCurrentTimesheet,
  updateCurrentTimecards,
  updateCurrentHours,
  updateCurrentEarnings,
  addPeopleToCurrentTimeCards,
  removeCurrentTimeCard,
  updateCurrentTimeCardField,
  updateCurrentTimeCardHours,
  addCurrentEarningsCards,
  removeCurrentEarningsCard,
  updateCurrentEarningsCardField,
  setHasChanges,
  updateNewComment
} from "../../redux/actions/currentEditActions";
import { loadPeople } from "../../redux/actions/personActions";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import ToastErrors from "../common/ToastErrors";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Modal from "../common/Modal";
import TimesheetDuplicates from "./TimesheetDuplicates";

const TimeSheetForm = ({
  tsid,
  isEditing,
  updateTimesheetStatus,
  loadTimesheetDeep,
  unlockTimesheet,
  clearCurrentEdit,
  timesheet,
  addPeopleToCurrentTimeCards,
  addCurrentEarningsCards,
  payPeriodStart,
  timeCards,
  earningsCards,
  newComment,
  hasChanges,
  people,
  loadPeople,
  ...props
}) => {
  const leaving = useRef(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [adminEdit, setAdminEdit] = useState(false);
  const [initialLoading, setInitialLoading] = useState(!leaving.current);
  const { promiseInProgress } = usePromiseTracker();
  const [errorMessage, setErrorMessage] = useState("");
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [duplicates, setDuplicates] = useState({ positions: [], earnings: [] });

  const setHasChanges = changes => {
    return props.setHasChanges(changes);
  };
  // Initial page load of all timesheet data
  // Dispatch actions to load data; don't call API directly.
  useEffect(() => {
    let didCancel = false;
    // console.log("initial,leaving,editing", initialLoading, leaving.current, isEditing);
    if (props.user && !isEditing && initialLoading && !leaving.current) {
      // console.log("**loading data");
      if (!didCancel) {
        trackPromise(
          loadTimesheetDeep(tsid, props.user.id)
            .then(() => setErrorMessage(""))
            .catch(e => {
              console.error("Failed to load timesheet data.", e);
              if (!didCancel)
                setErrorMessage("The timesheet did not load completely.");
            })
            .finally(() => {
              // console.log("**finally loaded", didCancel);
              // if (!didCancel)
              leaving.current = true;
              setInitialLoading(false);
            })
        );
      }
      // else console.log("**cancelled before load");
    }
    return () => {
      didCancel = true;
    };
  }, [tsid, isEditing, loadTimesheetDeep, initialLoading, props.user]);

  useEffect(() => {
    if (people && people.needToLoad) {
      loadPeople();
    }
  }, [people, loadPeople]);

  const needToReload = () => {
    const reload =
      (!timesheet || !timesheet.id) && !initialLoading && !errorMessage;
    if (reload) {
      // console.log("**reloading...");
      setInitialLoading(true);
    }
    return reload;
  };

  // Update timesheet totals when cards change
  const getTotals = useCallback(() => {
    const hours = totalHours(timeCards);
    const earnings = totalEarnings(earningsCards);
    return { hours, earnings };
  }, [timeCards, earningsCards]);

  useEffect(() => {
    if (timesheet) {
      const hours = getTotals().hours;
      if (timesheet.hours !== hours)
        updateCurrentTimesheet({ ...timesheet, hours });
    }
  }, [timeCards, getTotals, timesheet]);

  const tabLabels = ["Position & Pay", "Hours", "Earnings"].filter(
    t => t !== "Earnings" || hasPerm(props.user, perm.EET)
  );

  const unsavedComment = () => {
    return newComment && newComment.trim().length > 0;
  };
  const validateTab = tabIndex => {
    // console.log("validateTab", tabIndex);
    let errors = [];
    if (tabIndex === 0) {
      // Position is required; paidRate is required
      errors = viewableCards(timeCards).reduce((errs, card) => {
        if (!card.positionId || card.positionId === "0" || !card.position)
          errs.push(`Position requried for ${card.employeeName}`);
        if (card.paidRate === "")
          errs.push(`Paid Rate required for ${card.employeeName}`);
        return errs;
      }, []);
    }
    // TODO: validate other tabs?
    if (errors.length) {
      toast.error(ToastErrors(errors), {
        autoClose: 5000,
        position: toast.POSITION.TOP_CENTER
      });
      return false;
    }
    return true;
  };
  const handleTabClick = (index, lastIndex, e) => {
    // console.log("Save tab", lastIndex);
    props.resetTimeout();
    if (index === lastIndex) return false;
    if (promiseInProgress) {
      toast.warn(
        "Save in progress. Please wait for it to finish before switching tabs."
      );
      return false;
    }
    if (!validateTab(lastIndex)) return false;

    setSelectedTab(index);
    if (!canEdit() || !hasChanges || !timesheet) return;
    if (timesheet.status === "New" || timesheet.status === "Created")
      setTimesheetStatus("Draft");
    switch (lastIndex) {
      case 0:
        savePositionPay();
        break;
      case 1:
        saveHours().then(() => savePositionPay(false));
        break;
      case 2:
        saveEarnings();
        break;
      default:
        console.log("Unknown tab", lastIndex);
    }
    // setHasChanges(false);
  };

  /// Event handlers ///
  // *** Add/remove cards ***
  const handleAddPeople = (peopleToAdd, rowTypes) => {
    // translate rowTypes from descriptions to objects with ids
    const totalRows = timeCards.length + peopleToAdd.length * rowTypes.length;
    console.log("total rows after add: ", totalRows);
    trackPromise(
      addPeopleToCurrentTimeCards(
        peopleToAdd,
        rowTypes.map(t => lookupType(props.hoursTypes, t))
      )
    );
  };

  const handleAddEarnings = (peopleToAdd, rowTypes) => {
    const newCards = getEarningsCardsToAdd(
      people,
      peopleToAdd,
      rowTypes.map(t => lookupType(props.earningTypes, t)),
      tsid,
      getNextCardId(earningsCards, "earningsCardId"),
      timesheet.companyId
    );
    const totalRows = earningsCards.length + newCards.length;
    console.log("total earnings rows after add: ", totalRows);
    addCurrentEarningsCards(newCards);
    // setHasChanges(true);
  };

  const handleRemoveCard = cardId => {
    props.removeCurrentTimeCard(cardId);
    // setHasChanges(true);
  };
  // *** END Add/remove cards ***

  // *** Handle data changes ***
  const handleFieldChange = (action, cardId, field, value) => {
    switch (action) {
      case "UPDATE_FIELD":
        props.updateCurrentTimeCardField(cardId, field, value);
        break;
      // case "REMOVE_CARD":
      //   setTimeCards(cards => cards.filter(d => d.timecardId !== cardId));
      //   break;
      default:
    }
    // setHasChanges(true);
  };

  const handleRateBlur = (cardId, rate) => {
    const safeRate = formatDollars(rate, false);
    handleFieldChange("UPDATE_FIELD", cardId, "paidRate", safeRate);
  };

  // Hours
  const handleHoursChange = (cardId, index, value) => {
    props.updateCurrentTimeCardHours(cardId, index, value);
    // setHasChanges(true);
  };

  // Earnings
  const handleEarningsChange = (action, cardId, fieldName, value) => {
    switch (action) {
      case "UPDATE_FIELD":
        props.updateCurrentEarningsCardField(cardId, fieldName, value, true);
        break;
      case "FORMAT_FIELD":
        if (fieldName === "amount") {
          const safeValue = formatNumber(value, true, 4);
          props.updateCurrentEarningsCardField(
            cardId,
            fieldName,
            safeValue,
            false
          );
        }
        break;
      case "REMOVE_CARD":
        // Don't remove the card; set a deleted flag on it then filter what is displayed
        //    This is so we know what cards to delete when we save the timesheet.
        // setEarningsCards(e => e.filter(c => c.earningsCardId !== cardId));
        props.removeCurrentEarningsCard(cardId);
        break;
      default:
    }
    // setHasChanges(true);
  };
  // *** END Handle data changes ***

  // *** Button events ***
  const saveAndClose = (newStatus = "Draft") => {
    console.log("Save tab " + selectedTab, newStatus);
    if (!validateTab(selectedTab)) return;
    // // Reset hasChanges here so when we navigate it won't get blocked.
    // setHasChanges(false);
    let promises = [];
    switch (selectedTab) {
      case 0:
        promises = [savePositionPay()];
        break;
      case 1:
        promises = [saveHours().then(() => savePositionPay(false))];
        break;
      case 2:
        promises = [saveEarnings()];
        break;
      default:
        promises = [];
    }
    if (adminEdit) {
      newStatus = timesheet.status;
      setAdminEdit(false);
    }
    // The issue is the timesheet is in 2 places in the store (list for home page and in currentEdit).
    //       Need to normalize to keep in one place to update.
    Promise.allSettled(promises).then(
      resp => setTimesheetStatus(newStatus)
      //.then(() =>
      // clearCurrentEdit(props.user.id).then(() => props.history.push("/"))
      // )
    );
  };

  const equalTimecard = (a, b) => {
    return (
      a.moranId === b.moranId &&
      a.employeeName === b.employeeName &&
      a.hoursTypeId === b.hoursTypeId &&
      a.division === b.division &&
      a.position === b.position &&
      a.defaultRate === b.defaultRate &&
      a.overrideRate === b.overrideRate &&
      a.paidRate === b.paidRate
    );
  };
  const equalEarnings = (a, b) => {
    return (
      a.moranId === b.moranId &&
      a.employeeName === b.employeeName &&
      a.position === b.position &&
      a.earningTypeId === b.earningTypeId &&
      a.amount === b.amount &&
      a.checkNum === b.checkNum
    );
  };
  const validateNoDuplicates = () => {
    let posDupes = [];
    /// Sort by all the fields considered in duplicate check
    viewableCards(timeCards).sort((a, b) => {
      if (a.moranId < b.moranId) return -1;
      if (a.moranId > b.moranId) return 1;
      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;
      if (a.hoursTypeId < b.hoursTypeId) return -1;
      if (a.hoursTypeId > b.hoursTypeId) return 1;
      if (a.division < b.division) return -1;
      if (a.division > b.division) return 1;
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      if (a.defaultRate < b.defaultRate) return -1;
      if (a.defaultRate > b.defaultRate) return 1;
      if (a.overrideRate < b.overrideRate) return -1;
      if (a.overrideRate > b.overrideRate) return 1;
      if (a.paidRate < b.paidRate) return -1;
      if (a.paidRate > b.paidRate) return 1;
      // console.log("duplicate", a, b);
      // All those fields are equal if we get to here
      if (!posDupes.find(d => equalTimecard(a, d))) posDupes.push(a);
      return 0;
    });
    // console.log(posDupes);

    let earnDupes = [];
    viewableEarnings(earningsCards).sort((a, b) => {
      if (a.moranId < b.moranId) return -1;
      if (a.moranId > b.moranId) return 1;
      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;
      if (a.position < b.position) return -1;
      if (a.position > b.position) return 1;
      if (a.earningTypeId < b.earningTypeId) return -1;
      if (a.earningTypeId > b.earningTypeId) return 1;
      if (a.amount < b.amount) return -1;
      if (a.amount > b.amount) return 1;
      if (a.checkNum < b.checkNum) return -1;
      if (a.checkNum > b.checkNum) return 1;
      if (!earnDupes.find(d => equalEarnings(a, d))) earnDupes.push(a);
      return 0;
    });
    // console.log(earnDupes);

    if (posDupes.length || earnDupes.length) {
      setDuplicates({ positions: posDupes, earnings: earnDupes });
      setShowDuplicates(true);
      return false;
    }

    return true;
  };
  const hideDuplicates = () => {
    setShowDuplicates(false);
  };

  const verifyComment = () => {
    return (
      !unsavedComment() ||
      window.confirm(
        "You started entering a Comment, but you did not save it. Do you want to abandon the comment? To save the comment, click Cancel then Add Comment. To abandon the comment, click OK."
      )
    );
  };
  const handleSave = () => {
    if (verifyComment()) {
      props.updateNewComment("");
      saveAndClose();
    }
  };
  const handleApprove = () => {
    if (verifyComment()) {
      props.updateNewComment("");
      if (validateNoDuplicates()) saveAndClose("Approved");
    }
  };
  const handleReopen = () => {
    setTimesheetStatus("Draft");
  };
  const handleEdit = () => {
    if (hasPerm(props.user, perm.ET)) {
      setAdminEdit(true);
      // Lock timesheet
    }
  };

  const handleExit = evt => {
    evt.preventDefault();
    const message =
      (unsavedComment()
        ? "There is an unsaved comment."
        : "There are unsaved changes.") + " Are you sure you want to Exit?";
    const hadChanges = hasChanges;
    // Reset hasChanges here so if we decide to navigate it won't get blocked.
    setHasChanges(false);
    if (!(hadChanges || unsavedComment()) || window.confirm(message)) {
      // Unlock timesheet and remove the currentEdit
      // This is key to indicate we're leaving BEFORE clearing the current timesheet since that triggers a reload
      leaving.current = true;
      clearCurrentEdit(props.user.id)
        .then(() => {
          // console.log("**cleared, now leaving");
          props.history.push("/");
        })
        .catch(error => {
          console.error(error);
          setErrorMessage(`Failed to exit timesheet: ${error}`);
        });
    } else {
      // we cancelled the navigation, so reset hasChanges to what it was before
      setHasChanges(hadChanges);
    }
  };
  // *** END Button events ***

  /// Support functions ///
  const setTimesheetStatus = status => {
    // setTimesheet(t => ({ ...t, status }));
    return updateTimesheetStatus(status);
  };
  const savePositionPay = (showToast = true) => {
    return trackPromise(
      props.updateCurrentTimecards().then(() => {
        if (showToast) toast.success("Position & Pay saved");
      })
    );
  };

  const saveHours = () => {
    return trackPromise(
      props.updateCurrentHours().then(() => {
        toast.success("Hours saved");
      })
    );
  };

  const saveEarnings = () => {
    return trackPromise(
      props.updateCurrentEarnings(timesheet, earningsCards).then(() => {
        toast.success("Earnings saved");
      })
    );
  };

  const handleUnlock = () => {
    console.log("unlocking timesheet for ", props.user.id);
    unlockTimesheet(props.user.id).then(() => {
      // set leaving to false to allow a reload of the timesheet
      leaving.current = false;
      toast.info("Timesheet unlocked");
    });
  };

  const getPeopleInUse = () => {
    return [...new Set(timeCards.map(c => (c ? c.employeeId : null)))];
  };

  // Populate the isShoreside property from person record since it's not stored in the timecard
  const timeCardData = () => {
    return timeCards.map(tc => ({
      ...tc,
      isShoreside:
        people && people.byId[tc.employeeId]
          ? people.byId[tc.employeeId].isShoreside
          : false
    }));
  };

  const defaultDivision = () => {
    let division = timesheet && timesheet.division;
    /*
    const divisionReplacements = {
      equivalents: ["Moran Tank Barge", "Moran Dry Bulk Carriers", "Moran Albany"],
      replacement: "Moran NYNJ"
    };
    if (divisionReplacements.equivalents.includes(division))
      division = divisionReplacements.replacement;
    */
    return division;
  };

  /// *** Button Visibility ***
  const isLockedByMe = () => timesheet.lockedBy === props.user.id;

  const canEdit = () => {
    if (timesheet)
      if (
        timesheet.status === "New" ||
        timesheet.status === "Created" ||
        timesheet.status === "Draft" ||
        (adminEdit && hasPerm(props.user, perm.ET))
      ) {
        // check user
        if (isLockedByMe()) return true;
      }
    return false;
  };
  const canShowApprove = () => {
    if (!hasPerm(props.user, perm.AT)) return false;
    if (!timesheet) return false;
    switch (timesheet.status) {
      case "New":
      case "Created":
      case "Draft":
        return isLockedByMe();
      default:
        return false;
    }
  };

  const canShowReopen = () => {
    return (
      hasPerm(props.user, perm.ROT) &&
      timesheet &&
      timesheet.status === "Approved" &&
      isLockedByMe()
    );
  };

  const canShowEdit = () => {
    return (
      !adminEdit &&
      hasPerm(props.user, perm.ET) &&
      timesheet &&
      timesheet.status === "Closed" &&
      isLockedByMe()
    );
  };
  /// *** END Button Visibility ***

  /// render ///
  return (
    <>
      {initialLoading || needToReload() ? (
        <Spinner />
      ) : (
        <>
          <TimeSheetInfo
            totals={getTotals()}
            tsid={props.tsid}
            info={timesheet || {}}
            saving={promiseInProgress}
            canEdit={canEdit()}
            canShowApprove={canShowApprove()}
            canShowReopen={canShowReopen()}
            canShowEdit={canShowEdit()}
            handleSave={handleSave}
            handleApprove={handleApprove}
            handleReopen={handleReopen}
            handleEdit={handleEdit}
            handleExit={handleExit}
            handleUnlock={handleUnlock}
            errorMessage={errorMessage}
          />
          <div className="App-content">
            <div className="tab-container">
              <Tabs selectedTabClassName="active" onSelect={handleTabClick}>
                <TabList className="timesheet-tablist">
                  {tabLabels.map(t => (
                    <Tab key={t} className="timesheet-tab">
                      {t}
                    </Tab>
                  ))}
                </TabList>
                <TabPanel className="timesheet-tab-panel">
                  <TimeSheetPositionPay
                    timeSheetData={timeCardData()}
                    division={defaultDivision()}
                    companyId={timesheet && timesheet.companyId}
                    onFieldChange={handleFieldChange}
                    onAddPeople={handleAddPeople}
                    onRemoveCard={handleRemoveCard}
                    onRateBlur={handleRateBlur}
                    canEdit={canEdit()}
                  />
                </TabPanel>
                <TabPanel>
                  <TimeSheetHours
                    timeSheetRows={timeCardData()}
                    startDate={timesheet && timesheet.payPeriodStart}
                    onHoursChange={handleHoursChange}
                    onFieldChange={handleFieldChange}
                    canEdit={canEdit()}
                  />
                </TabPanel>
                {hasPerm(props.user, perm.EET) && (
                  <TabPanel>
                    <TimeSheetEarnings
                      data={viewableEarnings(earningsCards)}
                      peopleIds={getPeopleInUse()}
                      companyId={timesheet && timesheet.companyId}
                      onChange={handleEarningsChange}
                      onAddPeople={handleAddEarnings}
                      canEdit={canEdit()}
                    />
                  </TabPanel>
                )}
              </Tabs>
            </div>
            <div className="comments-container">
              <TimesheetComments tsid={tsid} canEdit={canEdit()} />
            </div>
          </div>
          <Modal
            isOpen={promiseInProgress}
            modalClass="collapse"
            hideHeader={true}
          >
            <>
              <h1>Saving...</h1>
              <p>Please wait while we save your changes.</p>
              <Spinner />
            </>
          </Modal>
          <Modal
            isOpen={showDuplicates}
            modalClass="collapse-x"
            onClose={hideDuplicates}
            title="Duplicate Rows"
          >
            <TimesheetDuplicates duplicates={duplicates} />
          </Modal>
        </>
      )}
    </>
  );
};

// Redux
function mapStateToProps(state) {
  const timeCards = state.currentEdit.timecards ?? [];
  const earningsCards = state.currentEdit.earnings ?? [];
  const payPeriodStart =
    state.currentEdit &&
    state.currentEdit.timesheet &&
    state.currentEdit.timesheet.payPeriodStart;
  const timesheet = state.currentEdit.timesheet || {};
  return {
    user: state.user,
    // current: state.currentEdit,
    people: state.people,
    timesheet,
    timeCards,
    earningsCards,
    newComment: state.currentEdit && state.currentEdit.newComment,
    payPeriodStart,
    hoursTypes: state.hoursTypes,
    earningTypes: state.earningTypes,
    isEditing: state.currentEdit && state.currentEdit.isEditing,
    hasChanges: state.currentEdit && state.currentEdit.hasChanges ? true : false
  };
}
const mapDispatchToProps = {
  updateTimesheetStatus,
  loadTimesheetDeep,
  unlockTimesheet,
  clearCurrentEdit,
  updateCurrentTimesheet,
  updateCurrentTimecards,
  updateCurrentHours,
  updateCurrentEarnings,
  addPeopleToCurrentTimeCards,
  removeCurrentTimeCard,
  updateCurrentTimeCardField,
  updateCurrentTimeCardHours,
  addCurrentEarningsCards,
  removeCurrentEarningsCard,
  updateCurrentEarningsCardField,
  setHasChanges,
  updateNewComment,
  loadPeople,
  resetTimeout
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TimeSheetForm)
);
