import React from "react";

const Error = props => {
  //   console.log(props);
  return (
    <div className="error-container">
      <h3>{props.heading ?? 'Something went wrong:'}</h3>
      <p>{props.message}</p>
    </div>
  );
};

export default Error;
