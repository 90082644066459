import * as types from "./actionTypes";
import * as personApi from "../../api/personApi";

export function loadPeople() {
  return async function(dispatch) {
    return personApi
      .getPeople()
      .then(people => {
        dispatch({ type: types.people.LOAD_PEOPLE_SUCCESS, people });
      })
      .catch(error => {
        throw error;
      });
  };
}
