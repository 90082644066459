import { timesheet, currentEdit } from "../actions/actionTypes";
import initialState from "./initialState";
import { totalHoursDollars } from "../../utils/timesheetUtils";

export default function timesheetReducer(
  state = initialState.timesheets,
  action
) {
  switch (action.type) {
    case timesheet.LOAD_TIMESHEETS_SUCCESS:
      return {
        byId: action.timesheets.reduce((collector, sheet) => {
          return { ...collector, [sheet.id]: sheet };
        }, {}),
        allIds: action.timesheets.map(t => t.id),
        needToLoad: false,
        isFetching: false
      };
    case timesheet.LOAD_TIMESHEETS_FAILURE:
      return {
        ...state,
        needToLoad: false,
        isFetching: false,
        error: action.error
      };
    case timesheet.LOAD_TIMESHEETS_START:
    case timesheet.LOAD_TIMESHEET_START:
      return { ...state, isFetching: true };
    case timesheet.LOAD_TIMESHEET_SUCCESS:
    case timesheet.UPDATE_TIMESHEET_STATUS_SUCCESS:
      if (action.sheet && action.sheet.id) {
        return {
          ...state,
          byId: { ...state.byId, [action.sheet.id]: action.sheet },
          needToLoad: true,
          isFetching: false
        };
      } else {
        // console.log("load or update success but no sheet", action.type);
        return { ...state, isFetching: false };
      }
    case timesheet.ADD_TIMESHEET_SUCCESS:
    case timesheet.INVALIDATE_TIMESHEETS:
      return { ...state, needToLoad: true };
    // return [...state, action.response.result];
    case timesheet.UPDATE_TIMESHEET_STATUS_START:
      return { ...state, isFetching: true };
    case timesheet.UPDATE_TIMESHEET_HOURS_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.sheet.id]: { ...action.sheet, hours: action.hours }
        },
        needToLoad: false,
        isFetching: false
      };
    case currentEdit.LOAD_TIMECARDS_AND_HOURS_SUCCESS:
      if (action.timecards.length === 0) return state;
      const hoursSub = totalHoursDollars(action.timecards);
      const tsid = action.timecards[0].timesheetId;
      return {
        ...state,
        byId: {
          ...state.byId,
          [tsid]: {
            ...state.byId[tsid],
            hoursSubtotal: hoursSub,
            tugTotal: state.byId[tsid].earnings + hoursSub
          }
        }
      };
    case timesheet.UPDATE_TIMESHEET_START:
      return state;
    case timesheet.UPDATE_TIMESHEET_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.sheet.id]: { ...action.sheet } }
      };
    default:
      return state;
  }
}
