import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatDateRange } from "../../utils/utils";
import * as payPeriodActions from "../../redux/actions/payPeriodActions";
import { hasPerm, perm } from "../../utils/userUtils";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import Error from "../common/Error";

const ALL_APPROVED = "All timesheets Approved";

const PayPeriodMgmtPage = ({
  payPeriods,
  needToLoad,
  actions,
  user,
  dataExport,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // console.log("PayPeriodMgmt useEffect");
    if (needToLoad) {
      // Load payPeriods into the redux store
      setLoading(true);
      actions
        .loadPayPeriods()
        .catch(error => {
          console.error("Whoops!", error);
          setErrorMessage("The Pay Periods could not be loaded.");
        })
        .finally(() => setLoading(false));
    }
  }, [needToLoad, actions]);

  // Events //
  const handleStatusChange = evt => {
    const title = evt.target.title;
    const action = evt.target.innerText;
    console.log(action, evt.target.id, title);
    const period = payPeriods.byId[evt.target.id];
    // console.log(period);
    switch (action) {
      case "Close":
        if (title === ALL_APPROVED) {
          if (
            window.confirm(
              `Are you sure you want to ${action} this pay period for ${period.companyCode}?`
            )
          )
            actions.closePayPeriod(period);
        } else {
          toast.warn(title, { position: toast.POSITION.TOP_CENTER });
        }
        break;
      case "Process":
        if (
          window.confirm(
            `Are you sure you want to ${action} this pay period for ${period.companyCode}?`
          )
        )
          actions
            .processPayPeriod(period)
            .then(() => actions.createExport(period.id));
        break;
      case "Open":
        if (
          window.confirm(
            `Are you sure you want to ${action} this pay period for ${period.companyCode}?`
          )
        )
          actions.openPayPeriod(period);
        break;
      default:
    }
  };

  const handleDownload = companyCode => {
    const ddl = document.getElementById(companyCode);
    const ppId = ddl.value;
    const payPeriod = payPeriods.byId[ppId];
    const filename = `${companyCode.replace(/[^a-zA-Z]/g, "")}-${ppId}.csv`;
    console.log(filename);
    // Possible prerequisite action; default to an empty completed action
    let prereq = Promise.resolve(true);
    if (payPeriod.status === "Closed") {
      // Create a new set of download data first
      console.log("creating new export first");
      prereq = actions.createExport(ppId);
    }
    prereq.then(success => {
      if (success) {
        actions
          .exportPayPeriod(ppId)
          .then(response => {
            // console.log(response);
            const blob = getCsvBlob(response.data);
            if (blob) downloadData(blob, filename);
            else
              toast.warn(
                "No data to download for this pay period. " +
                  payPeriod.displayName
              );
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        toast.warn("Unable to create the export.");
      }
    });
  };

  const getCsvBlob = data => {
    if (data && data.length > 0) {
      const keys = Object.keys(data[0]);
      // console.log(keys);
      const header = keys.join(",");
      const rows = data.map(d => keys.map(k => formatField(d[k])).join(","));
      const csv = [header, ...rows].join("\n");
      // console.log(csv);
      return new Blob([csv]);
    }
  };
  const formatField = value => {
    // handle value based on type
    switch (typeof value) {
      case "string":
        // wrap in quotes, escape contained quotes
        return `"${value.replace('"', '""')}"`;
      case "number":
        return value;
      case "object":
        // null objects get returned as is: null
        if (!value) return value;
        // the current download format has nothing that
        return value.toString();
      default:
        console.log(value, typeof value);
        return value;
    }
  };

  const downloadData = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  };

  const shapePayPeriods = () => {
    // console.log("payPeriods", payPeriods);
    const keys = Object.keys(payPeriods.byId);
    const revKeys = [...keys].reverse();
    // console.log(payPeriods);
    const companyCodes = [
      ...new Set(keys.map(k => payPeriods.byId[k].companyCode))
    ];
    // console.log(companyCodes);
    const shaped = companyCodes.map(c => {
      const next = keys.find(
        k =>
          payPeriods.byId[k].companyCode === c &&
          (payPeriods.byId[k].status === "New" ||
            payPeriods.byId[k].status === "Created")
      );
      const current = revKeys.find(
        k =>
          payPeriods.byId[k].companyCode === c &&
          payPeriods.byId[k].status !== "New" &&
          payPeriods.byId[k].status !== "Created" &&
          (!next ||
            payPeriods.byId[k].startDate < payPeriods.byId[next].startDate)
      );
      // Get a list of previous processed pay periods for download
      const processed = keys
        .filter(
          k =>
            payPeriods.byId[k].companyCode === c &&
            (payPeriods.byId[k].status === "Processed" ||
              payPeriods.byId[k].status === "Closed")
        )
        .map(k => payPeriods.byId[k])
        .sort((a, b) => {
          return a.startDate === b.startDate
            ? 0
            : a.startDate < b.startDate
            ? 1
            : -1;
          // const aDate = new Date(a.startDate);
          // const bDate = new Date(b.startDate);
          // // console.log(aDate, bDate);
          // return aDate === bDate ? 0 : aDate < bDate ? 1 : -1;
        })
        .slice(0, 10); // no more than 10 most recent items in dropdown

      const emptyPeriod = {
        id: 0,
        startDate: "",
        endDate: "",
        status: "None",
        companyCode: c
      };
      return {
        companyCode: c,
        current: current ? payPeriods.byId[current] : emptyPeriod,
        next: next ? payPeriods.byId[next] : emptyPeriod,
        processed
      };
    });

    // console.log(shaped);
    return shaped;
  };

  const getButtonLabel = status => {
    switch (status) {
      case "Approved":
      case "Opened":
        return hasPerm(user, perm.CPP) ? "Close" : "";
      case "Closed":
        return hasPerm(user, perm.AT) ? "Process" : "";
      case "Processed":
      case "None":
        return hasPerm(user, perm.OPP) ? "Open" : "";
      default:
        return "";
    }
  };

  const getTitleMessage = pp => {
    return pp.current.status === "Opened"
      ? pp.current.nonApprovedTimesheets
        ? `${pp.current.nonApprovedTimesheets} ${
            pp.current.nonApprovedTimesheets === 1 ? "timesheet" : "timesheets"
          } not Approved`
        : pp.current.approvedTimesheets
        ? ALL_APPROVED
        : "No timesheets to Approve"
      : "";
  };

  return (
    <>
      <div className="modal-content">
        {loading ? (
          <Spinner />
        ) : errorMessage ? (
          <Error message={errorMessage} />
        ) : (
          <div className="pay-container">
            {dataExport.isFetching && <Spinner extraClass="overlay" />}
            <table>
              <thead>
                <tr>
                  <th className="end-column">Company</th>
                  <th colSpan="2" className="end-column">
                    Current Pay Period
                  </th>
                  <th colSpan="2" className="end-column">
                    Export Pay Period
                  </th>
                  <th colSpan="2">Next Pay Period</th>
                </tr>
              </thead>
              <tbody>
                {shapePayPeriods().map((pp, i) => (
                  <tr
                    key={pp.companyCode}
                    className={i % 2 ? "normal-bkg" : "alternate-bkg"}
                  >
                    <td className="end-column" title={pp.current.displayName}>
                      {pp.companyCode}
                    </td>
                    <td title={pp.current.status} className="button-column">
                      {formatDateRange(
                        pp.current.startDate,
                        pp.current.endDate
                      )}
                    </td>
                    <td className="end-column button-column">
                      <button
                        type="button"
                        id={pp.current.id}
                        onClick={handleStatusChange}
                        style={{
                          display:
                            pp.current.status === "Processed" ||
                            pp.current.status === "None" ||
                            //pp.current.nonApprovedTimesheets > 0 ||
                            !getButtonLabel(pp.current.status)
                              ? "none"
                              : ""
                        }}
                        // disabled={
                        //   pp.current.status === "Opened" &&
                        //   (pp.current.nonApprovedTimesheets ||
                        //     !pp.current.approvedTimesheets)
                        // }
                        title={getTitleMessage(pp)}
                      >
                        {getButtonLabel(pp.current.status)}
                      </button>
                    </td>
                    <td className="button-column">
                      <select
                        className="pay-period"
                        id={pp.companyCode}
                        style={{ display: pp.processed.length ? "" : "none" }}
                      >
                        {pp.processed.map(p => (
                          <option key={p.id} value={p.id}>
                            {formatDateRange(p.startDate, p.endDate)}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="end-column button-column">
                      <button
                        type="button"
                        onClick={() => handleDownload(pp.companyCode)}
                        style={{ display: pp.processed.length ? "" : "none" }}
                      >
                        Download
                      </button>
                    </td>
                    <td className="button-column">
                      {formatDateRange(pp.next.startDate, pp.next.endDate)}
                    </td>
                    <td className="button-column">
                      <button
                        type="button"
                        id={pp.next ? pp.next.id : 0}
                        onClick={handleStatusChange}
                        style={{
                          display:
                            (pp.current.status === "Processed" ||
                              pp.current.status === "None") &&
                            pp.next.status !== "None" &&
                            getButtonLabel(pp.current.status)
                              ? ""
                              : "none"
                        }}
                        disabled={!pp.next}
                      >
                        {getButtonLabel(pp.current.status)}
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="7">
                    <span className="instructions-header">NOTES:</span>
                    <ul>
                      <li>
                        When the Current Pay Period has open timesheets, no
                        action can be taken on it.
                      </li>
                      <li>
                        When all timesheets in the Current Pay Period are
                        approved, the <b>Close</b> button will be enabled.
                      </li>
                      <li>
                        After the Current Pay Period is Closed, the{" "}
                        <b>Process</b> button will appear.
                      </li>
                      <li>
                        After the Current Pay Period is Processed, then the Next
                        Pay Period will have an <b>Open</b> button.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <div></div>
          </div>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    dataExport: state.dataExport,
    payPeriods: state.payPeriods,
    needToLoad: state.payPeriods.needToLoad
  };
}

// const mapDispatchToProps = { loadPayPeriods };
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(payPeriodActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPeriodMgmtPage);
