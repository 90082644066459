import React from "react";
import "../../App.css";
import { connect } from "react-redux";
import {
  formatDollars,
  formatNumber,
  formatDateRange
} from "../../utils/utils";
import { hasPerm, perm } from "../../utils/userUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import Error from "../common/Error";

const TimeSheetInfo = props => {
  // console.log(props);
  const unlockTimesheet = () => {
    if (props.handleUnlock) props.handleUnlock();
  };

  return (
    <>
      <div className="page-header">
        <div className="page-heading">
          <span className="field-label">Asset</span>:&nbsp;
          {props.info.asset}
        </div>
        <div className="page-buttons">
          {props.canEdit && (
            <button onClick={props.handleSave} disabled={props.saving}>
              Save
            </button>
          )}
          {props.canShowApprove && (
            <button onClick={props.handleApprove} disabled={props.saving}>
              Approve
            </button>
          )}
          {props.canShowReopen && (
            <button onClick={props.handleReopen} disabled={props.saving}>
              Re-open
            </button>
          )}
          {props.canShowEdit && (
            <button onClick={props.handleEdit} disabled={props.saving}>
              Edit
            </button>
          )}
          <button onClick={props.handleExit} disabled={props.saving}>
            Exit
          </button>
        </div>
      </div>
      <div
        className={
          "timesheet-info" +
          (props.info.lockedBy !== props.user.id ? " locked" : "")
        }
      >
        <div className="header-left">
          <div className="heading">
            <span className="field-label">Division</span>:&nbsp;
            {props.info.division}
          </div>
          <div className="heading">
            <span className="field-label">Payroll Period</span>:&nbsp;
            {formatDateRange(
              props.info.payPeriodStart,
              props.info.payPeriodEnd
            )}
          </div>
          {props.info.lockedBy !== props.user.id && (
            <div className="heading">
              {hasPerm(props.user, perm.UT) ? (
                <FontAwesomeIcon
                  icon={faUserLock}
                  title="Click to Unlock"
                  onClick={unlockTimesheet}
                />
              ) : (
                <FontAwesomeIcon icon={faUserLock} />
              )}{" "}
              Locked by {props.info.lockedByName}
            </div>
          )}
        </div>
        {props.errorMessage ? <Error message={props.errorMessage} /> : null}
        <div className="header-right">
          <div className="timesheet-info-totals">
            <table>
              <tbody>
                <tr>
                  <td className="field-label number">Total Hours:</td>
                  <td>{formatNumber(props.totals.hours, true)}</td>
                </tr>
                {hasPerm(props.user, perm.EET) && (
                  <tr>
                    <td className="field-label number">Total Earnings:</td>
                    <td>${formatDollars(props.totals.earnings, true, 0)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

// Redux
function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(TimeSheetInfo);
// export default TimeSheetInfo;
