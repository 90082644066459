import { lookups, health } from "./actionTypes";
import * as lookupApis from "../../api/lookupsApi";
import { getApiStatus } from "../../api/healthApi";

export function loadEarningTypes() {
  return async function (dispatch) {
    return lookupApis
      .getEarningTypes()
      .then(earningTypes => {
        // console.log("loaded earningTypes");
        // console.log(earningTypes);
        dispatch({ type: lookups.LOAD_EARNINGTYPES_SUCCESS, earningTypes });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadHoursTypes() {
  return async function (dispatch) {
    return lookupApis
      .getHoursTypes()
      .then(hoursTypes => {
        dispatch({ type: lookups.LOAD_HOURSTYPES_SUCCESS, hoursTypes });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function loadPositions() {
  return async function (dispatch) {
    return lookupApis
      .getPositions()
      .then(positions => {
        dispatch({ type: lookups.LOAD_POSITIONS_SUCCESS, positions });
      })
      .catch(error => {
        throw error;
      });
  };
}

export function getApiVersion() {
  return async function (dispatch) {
    return getApiStatus()
      .then(status => {
        if (status) dispatch({ type: health.LOAD_HEALTH_SUCCESS, status });
        else dispatch({ type: health.LOAD_HEALTH_FAILURE });
      })
      .catch(() => dispatch({ type: health.LOAD_HEALTH_FAILURE }));
  };
}

export function loadRoles() {
  return async function (dispatch) {
    return lookupApis
      .getRoles()
      .then(roles => {
        dispatch({ type: lookups.LOAD_ROLES_SUCCESS, roles });
      })
      .catch(error => {
        throw error;
      });
  };
}
