import { payPeriod as types, timesheet } from "./actionTypes";
import * as payPeriodApi from "../../api/payPeriodApi";
import { getPayPeriodExport, createPayPeriodExport } from "../../api/exportApi";

export function loadPayPeriods() {
  return async function (dispatch) {
    dispatch({ type: types.GET_PAYPERIODS_START });
    return payPeriodApi.getPayPeriods().then(periods => {
      dispatch({ type: types.GET_PAYPERIODS_SUCCESS, periods });
    });
    // .catch(error => console.error(error));
  };
}

export function closePayPeriod(payPeriod) {
  return async function (dispatch) {
    dispatch({ type: types.CLOSE_PAYPERIOD_START });
    return payPeriodApi.closePayPeriod(payPeriod).then(response => {
      if (response.ok) {
        dispatch({ type: types.CLOSE_PAYPERIOD_SUCCESS, payPeriod });
        dispatch({ type: timesheet.INVALIDATE_TIMESHEETS });
      } else dispatch({ type: types.CLOSE_PAYPERIOD_FAILURE, payPeriod });
    });
  };
}

export function processPayPeriod(payPeriod) {
  return async function (dispatch) {
    dispatch({ type: types.PROCESS_PAYPERIOD_START });
    return payPeriodApi.processPayPeriod(payPeriod).then(response => {
      if (response.ok) {
        dispatch({ type: types.PROCESS_PAYPERIOD_SUCCESS, payPeriod });
        dispatch({ type: timesheet.INVALIDATE_TIMESHEETS });
      } else dispatch({ type: types.PROCESS_PAYPERIOD_FAILURE, payPeriod });
    });
  };
}

export function openPayPeriod(payPeriod) {
  return async function (dispatch) {
    dispatch({ type: types.OPEN_PAYPERIOD_START });
    return payPeriodApi.openPayPeriod(payPeriod).then(response => {
      if (response.ok)
        dispatch({ type: types.OPEN_PAYPERIOD_SUCCESS, payPeriod });
      else dispatch({ type: types.OPEN_PAYPERIOD_FAILURE, payPeriod });
    });
  };
}

/// Load the pay periods if they're not already loaded
export function assurePayPeriods() {
  return async function (dispatch, getState) {
    if (getState().payPeriods.needToLoad) {
      return dispatch(loadPayPeriods());
    } else {
      return Promise.resolve();
    }
  };
}

export function createExport(payPeriodId) {
  return async function (dispatch) {
    dispatch({ type: types.CREATE_EXPORT_PAYPERIOD_START });
    return createPayPeriodExport(payPeriodId)
      .then(response => {
        return dispatch({
          type: types.CREATE_EXPORT_PAYPERIOD_SUCCESS,
          response
        });
      })
      .catch(error =>
        dispatch({
          type: types.CREATE_EXPORT_PAYPERIOD_FAILURE,
          payPeriodId,
          error
        })
      );
  };
}

export function exportPayPeriod(payPeriodId) {
  return async function (dispatch, getState) {
    dispatch({ type: types.EXPORT_PAYPERIOD_START });
    const payPeriod = getState().payPeriods.byId[payPeriodId];
    return getPayPeriodExport(payPeriodId)
      .then(data => {
        if (data)
          return dispatch({
            type: types.EXPORT_PAYPERIOD_SUCCESS,
            payPeriod,
            data
          });
        else
          return dispatch({
            type: types.EXPORT_PAYPERIOD_FAILURE,
            payPeriodId
          });
      })
      .catch(error =>
        dispatch({
          type: types.EXPORT_PAYPERIOD_FAILURE,
          payPeriodId,
          error
        })
      );
  };
}
