import {
  handleResponse,
  handleError,
  handlePostResponse,
  handleDeleteResponse,
  handlePutResponse,
  getAuthHeader
} from "./apiUtils";
import { safeNumber } from "../utils/utils";
import { config } from "../tools/config";
const baseUrl = config.env.REACT_APP_API_ROOT;
const timesheetUrl = baseUrl + "/timesheet";
const timecardUrl = baseUrl + "/timecard";
const earningsUrl = baseUrl + "/timesheetearnings";

// Timesheets //
export function getTimesheets() {
  return fetch(timesheetUrl, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function getTimesheet(id) {
  return fetch(timesheetUrl + "/" + id, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function lockTimesheet(id, userId) {
  // Do a PUT to update the record through the web service API
  return getTimesheet(id).then(timesheet => {
    // console.log("locking timesheet", timesheet);
    if (timesheet) {
      if (!timesheet.lockedBy || timesheet.lockedBy === userId) {
        const payload = JSON.stringify({
          ...timesheet,
          lockedOn: new Date(),
          lockedBy: userId
        });
        // console.log(payload);
        return fetch(`${timesheetUrl}/${id}/lock`, {
          method: "PUT",
          headers: getAuthHeader(),
          body: payload
        }).then(handlePutResponse);
      } else {
        console.log("Timesheet already locked");
        return Promise.resolve(timesheet);
      }
    } else {
      return Promise.resolve();
    }
  });
}

export function unlockTimesheet(timesheet, userId) {
  const id = timesheet.id;
  // if (timesheet.lockedBy && timesheet.lockedBy === userId) {
  if (timesheet.lockedBy) {
    const payload = JSON.stringify({
      ...timesheet,
      lockedOn: null,
      lockedBy: null
    });
    return fetch(`${timesheetUrl}/${id}/unlock`, {
      method: "PUT",
      headers: getAuthHeader(),
      body: payload
    }).then(handlePutResponse);
  } else {
    console.log("Timesheet is not locked");
    return Promise.resolve(timesheet);
  }
}

export function updateTimesheet(sheet) {
  // const { timecards, ...payload } = sheet;
  // console.log("update timesheet", sheet, payload);
  return fetch(`${timesheetUrl}/${sheet.id}`, {
    method: "PUT",
    headers: getAuthHeader(),
    body: JSON.stringify(sheet)
  })
    .then(handlePutResponse)
    .catch(handleError);
}

export function getMissingTimesheets() {
  return fetch(`${timesheetUrl}/missing`, { headers: getAuthHeader() })
    .then(handleResponse)
    .catch(handleError);
}

export function addTimesheet(sheet) {
  console.log("add timesheet", sheet);
  return fetch(timesheetUrl, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify(sheet)
  })
    .then(handlePostResponse)
    .catch(handleError);
}

// Timecards //
export function getTimecards(timesheetId) {
  return fetch(timesheetUrl + "/" + timesheetId + "/timecards", {
    headers: getAuthHeader()
  })
    .then(handleResponse)
    .then(timecards =>
      (timecards || []).map(tc => ({
        ...tc,
        metadata: { isDirty: false, deleted: false, isNew: false }
      }))
    )
    .catch(handleError);
}

export async function getTimecardsWithHours(timesheetId) {
  let cards = await getTimecards(timesheetId);
  // console.log("awaited cards", cards);
  const cardHours = await getTimesheetHours(timesheetId);
  // console.log("awaited cardHours", cardHours);

  cards = cards.map(c => ({
    ...c,
    hours: cardHours.filter(h => h.timecardId === c.timecardId)
  }));

  return cards;
}

export function addTimecard(card) {
  const typedCard = getTypedCard(card);
  const { hours, timecardId, metadata, ...cardNoHours } = typedCard;
  // console.log("cardNoHours", cardNoHours);
  return fetch(timecardUrl, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify(cardNoHours)
  })
    .then(handlePostResponse)
    .catch(handleError);
}

export function updateTimecard(card) {
  const typedCard = getTypedCard(card);
  const { hours, metadata, ...cardNoHours } = typedCard;
  // console.log("cardNoHours", cardNoHours);
  if (metadata && metadata.isDirty)
    return fetch(`${timecardUrl}/${card.timecardId}`, {
      method: "PUT",
      headers: getAuthHeader(),
      body: JSON.stringify(cardNoHours)
    });
  else
    return Promise.resolve({
      action: "None",
      result: "No update to Timecard id " + card.timecardId
    });
}

export function deleteTimecard(card) {
  const { metadata } = card;
  if (metadata && metadata.deleted && !metadata.isNew)
    return fetch(`${timecardUrl}/${card.timecardId}`, {
      method: "DELETE",
      headers: getAuthHeader()
    });
}

export function processTimecard(card) {
  const { metadata } = card;
  if (metadata) {
    if (metadata.deleted && !metadata.isNew) return deleteTimecard(card);
    if (metadata.isNew && !metadata.deleted) return addTimecard(card);
    if (metadata.isDirty && !metadata.isNew) return updateTimecard(card);
  }
  return Promise.resolve({
    action: "None",
    result: "No change to Timecard id " + card.timecardId
  });
}

// Timecard Hours //
export function getTimesheetHours(timesheetId) {
  return fetch(`${timecardUrl}/${timesheetId}/hours`, {
    headers: getAuthHeader()
  })
    .then(handleResponse)
    .then(hourObjs => {
      return hourObjs;
    });
}

export function updateTimecardHoursBatch(cards) {
  const updatedHours = cards.reduce(
    (hours, card) => [
      ...hours,
      ...card.hours.filter(h => h.isDirty && !h.isNew)
    ],
    []
  );
  const payload = updatedHours.map(h => getTypedHours(h));
  return (
    fetch(`${baseUrl}/timecardhours`, {
      method: "PUT",
      headers: getAuthHeader(),
      body: JSON.stringify(payload)
    })
      .then(handlePutResponse)
      // .then(response => {
      //   // TODO: handle the response as a collection
      //   if (response.ok)
      //     return Promise.resolve({
      //       ...response,
      //       result: {
      //         ...response.result,
      //         isDirty: false
      //       }
      //     });
      //   else return Promise.resolve(response);
      // })
      .catch(handleError)
  );
}

export function updateTimecardHours(hourObjs) {
  // console.log("updateHours", hourObjs);
  const updatePromises = hourObjs.map(async hours => {
    if (!hours) return hours;
    const { isDirty, ...hoursNoMeta } = hours;
    if (isDirty) {
      let method = "PUT";
      let url = `${baseUrl}/timecardhours/${hours.id}`;
      let payload = getTypedHours(hoursNoMeta);
      if (hours && hours.id <= 0) {
        method = "POST";
        url = `${baseUrl}/timecardhours`;
        const { id, ...hoursNoId } = hoursNoMeta;
        payload = hoursNoId;
      }
      const handler = method === "PUT" ? handlePutResponse : handlePostResponse;
      // console.log("revised payload", payload);
      return await fetch(url, {
        method: method,
        headers: getAuthHeader(),
        body: JSON.stringify(payload)
      })
        .then(handler)
        .then(response => {
          if (response.ok)
            return Promise.resolve({
              ...response,
              result: {
                ...response.result,
                isDirty: false,
                isNew: false
              }
            });
          else return Promise.resolve(response);
        })
        .catch(handleError);
    } else
      return Promise.resolve({
        action: "None",
        result: "No change to hours " + hours.id
      }); // hoursNoMeta;
  });
  // console.log(updatePromises);
  return Promise.all(updatePromises);
}

// Earnings //
export function getTimesheetEarnings(timesheetId) {
  return fetch(`${timesheetUrl}/${timesheetId}/earnings`, {
    headers: getAuthHeader()
  })
    .then(handleResponse)
    .then(cards =>
      (cards || []).map(card => ({
        ...card,
        metadata: { isDirty: false, deleted: false }
      }))
    )
    .catch(handleError);
}

export function addEarnings(card) {
  const typedCard = getTypedEarnings(card);
  const { earningsCardId, metadata, ...cardNoId } = typedCard;
  return fetch(earningsUrl, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify(cardNoId)
  })
    .then(handlePostResponse)
    .then(response => {
      if (response.ok)
        return Promise.resolve({
          ...response,
          result: {
            ...response.result,
            metadata: {
              // Add metadata to response result object with old id
              isDirty: false,
              deleted: false,
              isNew: false,
              oldId: earningsCardId
            }
          }
        });
      else return Promise.resolve(response);
    })
    .catch(handleError);
}

export function updateEarnings(card) {
  const typedCard = getTypedEarnings(card);
  const { metadata, ...cardNoMeta } = typedCard;
  if (metadata && metadata.isDirty)
    return fetch(`${earningsUrl}/${card.earningsCardId}`, {
      method: "PUT",
      headers: getAuthHeader(),
      body: JSON.stringify(cardNoMeta)
    })
      .then(handlePutResponse)
      .catch(handleError);
}

export function deleteEarnings(card) {
  const { metadata } = card;
  if (metadata && metadata.deleted && !metadata.isNew)
    return fetch(`${earningsUrl}/${card.earningsCardId}`, {
      method: "DELETE",
      headers: getAuthHeader()
    })
      .then(handleDeleteResponse)
      .catch(handleError);
}

export function processEarnings(card) {
  const { metadata } = card;
  if (metadata) {
    if (metadata.deleted && !metadata.isNew) return deleteEarnings(card);
    if (metadata.isNew && !metadata.deleted) return addEarnings(card);
    if (metadata.isDirty && !metadata.isNew) return updateEarnings(card);
  }
  return Promise.resolve({
    action: "None",
    ok: true,
    result: card // "No change to Earnings id " + card.earningsCardId
  });
}

// Comments //
export function getComments(timesheetId) {
  return fetch(`${timesheetUrl}/${timesheetId}/comments`, {
    headers: getAuthHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addComment(comment) {
  // console.log(comment);
  return fetch(`${baseUrl}/timesheetcomment`, {
    method: "POST",
    headers: getAuthHeader(),
    body: JSON.stringify(comment)
  }).catch(handleError);
}

// Helpers //
function getTypedCard(card) {
  let result = { ...card };
  if (card) {
    if (card.paidRate) {
      try {
        const numRate = parseFloat(card.paidRate);
        result = { ...result, paidRate: numRate };
      } catch {
        result = { ...result, paidRate: null };
      }
    }
    if (card.hoursTypeId) {
      try {
        const num = parseInt(card.hoursTypeId);
        result = { ...result, hoursTypeId: num };
      } catch {
        result = { ...result, hoursTypeId: 0 };
      }
    }
    if (card.positionId) {
      try {
        const num = parseInt(card.positionId);
        result = { ...result, positionId: num };
      } catch {
        result = { ...result, positionId: 0 };
      }
    }
    // HACK: This should allow nulls, but the API currently doesn't
    if (!card.defaultRate) {
      result = { ...result, defaultRate: 0 };
    }
  }
  return result;
}

function getTypedEarnings(card) {
  let result = { ...card };
  if (card) {
    if (card.amount) {
      try {
        const numAmount = safeNumber(card.amount, 0);
        result = { ...result, amount: numAmount };
      } catch {
        result = { ...result, amount: 0 };
      }
    }
    if (card.earningTypeId) {
      try {
        const num = parseInt(card.earningTypeId);
        result = { ...result, earningTypeId: num };
      } catch {
        result = { ...result, earningTypeId: 0 };
      }
    }
    if (card.positionId) {
      try {
        const num = parseInt(card.positionId);
        result = { ...result, positionId: num };
      } catch {
        result = { ...result, positionId: 0 };
      }
    }
  }
  return result;
}

function getTypedHours(record) {
  const result = { ...record, hours: safeNumber(record.hours, null) };
  return result;
}
