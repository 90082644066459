import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";

export const BLANK = "(Blank)";
export const REGULAR = "Regular";
export const SHORESIDE_CHECK = "SHWRKTG";

export const safeNumber = (value, defaultValue = 0, allowNegative = false) => {
  const regx = allowNegative ? /[,$]/g : /[,$-]/g;
  const testStr =
    value === 0 ? "0" : (value || "").toString().replace(regx, "").trim();
  // console.log("safeNumber string", value, testStr);
  const testNum = Number(testStr);
  // console.log(testNum);
  return testStr === "" || isNaN(testNum) ? defaultValue : testNum;
};
export const formatNumber = (
  value,
  showCommas = false,
  decimals = 2,
  allowNegative = false
) => {
  // Make sure value is a number (or can be coerced into one)
  //   console.log(value);
  const num = safeNumber(value, NaN, allowNegative);
  //   console.log(num);
  if (isNaN(num)) return "";
  let str = num.toFixed(decimals);
  //   console.log(str);

  if (showCommas) {
    // Add commas every 3 digits to the left of the decimal.
    const parts = str.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   console.log(parts);
    str = parts.join(".");
  }
  return str;
};

export const formatDollars = (value, showCommas = true, decimals = 4) => {
  return formatNumber(value, showCommas, decimals);
};

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  currency: "USD",
  minimumFractionDigits: 2
});

export const formatUsd = value => {
  return usdFormatter.format(value);
};

// Utils
const dow = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const formatShortDate = (d, offset) => {
  // d parameter must be a Date
  const newDay = new Date(d);
  if (!isDate(newDay)) return "";
  newDay.setDate(newDay.getDate() + offset);
  return dow[newDay.getDay()] + " " + newDay.getDate();
};

export const formatDate = d => {
  const dt = new Date(d);
  if (!isDate(dt)) return "";
  return dt.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric"
  });
};

export const formatDateTime = d => {
  // console.log(d);
  const localDate = moment.tz(d, "UTC").tz(moment.tz.guess());
  return localDate.format("M/D/YYYY [at] h:mm a z");
};

export const formatDateRange = (start, end) => {
  return formatDate(start) + " - " + formatDate(end);
};

const isDate = d => {
  return (
    Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d.getTime())
  );
};

// Timesheet Utilities
export const timeCardSort = (a, b) => {
  // console.log(a, b);
  const aVal = a.employeeLastName + a.moranId + a.hoursType;
  const bVal = b.employeeLastName + b.moranId + b.hoursType;
  return aVal === bVal ? 0 : aVal > bVal ? 1 : -1;
};

export const viewableCards = data => {
  return data.filter(d => d.metadata && !d.metadata.deleted).sort(timeCardSort);
};

const earningsSort = (a, b) => {
  const aVal = a.employeeLastName + a.moranId + a.earningType;
  const bVal = b.employeeLastName + b.moranId + b.earningType;
  return aVal === bVal ? 0 : aVal > bVal ? 1 : -1;
};

export const viewableEarnings = data => {
  return (data || [])
    .filter(d => d.metadata && !d.metadata.deleted)
    .sort(earningsSort);
};

// Sort and Filter Ultilities
// Sorting and Filtering events //
export const handleSort = (direction, column, columnSort, setColumnSort) => {
  // console.log("Sort: ", column, direction);
  // Clicking the same sort a second time clears the sort
  if (direction === columnSort.direction && column === columnSort.column) {
    setColumnSort({ direction: "", column });
  } else {
    setColumnSort({ direction, column });
  }
};

export const handleFilter = (
  value,
  column,
  columnFilters,
  setColumnFilters,
  visibleOptions
) => {
  // Toggle whether the value is included in the set of filter options for this column.
  // Also remove any filters that are not currently showing
  // console.log("filter: ", column, value);
  if (columnFilters[column].includes(value)) {
    setColumnFilters({
      ...columnFilters,
      [column]: columnFilters[column].filter(
        f => f !== value && visibleOptions.includes(f)
      )
    });
  } else {
    setColumnFilters({
      ...columnFilters,
      [column]: [
        ...columnFilters[column].filter(f => visibleOptions.includes(f)),
        value
      ]
    });
  }
};

const blankToBottom = (a, b) => {
  return a === b ? 0 : a === BLANK ? 1 : b === BLANK ? -1 : a > b ? 1 : -1;
};

// Get the set of unique values in a column of an object.
// data parameter is an array of objects;
// col is the name of the property in the object
export const columnOptions = (data, col) => {
  return [
    ...new Set(
      (data || []).map(p => (p[col] && p[col].trim() !== "" ? p[col] : BLANK))
    )
  ].sort(blankToBottom);
};

export const filteredColumnOptions = (
  data,
  sortableColumns,
  columnFilters,
  col
) => {
  // Get filtered data based on all filters EXCEPT this col.
  const sortableNoCol = Object.keys(sortableColumns).reduce(
    (prev, cur) =>
      cur === col ? prev : { ...prev, [cur]: sortableColumns[cur] },
    {}
  );
  const filtersNoCol = Object.keys(columnFilters).reduce(
    (prev, cur) =>
      cur === col ? prev : { ...prev, [cur]: columnFilters[cur] },
    {}
  );
  const filteredData = filterOnColumns(data, sortableNoCol, filtersNoCol);
  const filteredColOptions = columnOptions(filteredData, sortableColumns[col]);
  return filteredColOptions;
};

// Filter on each sortable/filterable column's filter array; return the residual
// For Pay Period, filter on the displayed date, not the sortable date time.
export const filterOnColumns = (data, sortableColumns, columnFilters) => {
  return Object.keys(sortableColumns).reduce(
    (prev, cur) =>
      prev.filter(
        p =>
          columnFilters[cur].length === 0 ||
          columnFilters[cur].includes(
            !p[sortableColumns[cur]] ? BLANK : p[sortableColumns[cur]]
          ) ||
          (cur === "Pay Period" &&
            columnFilters[cur].includes(formatDate(p[sortableColumns[cur]])))
      ),
    data
  );
};

/// Return the description property from an item with a given id
/// opts is an array of objects with id and description properties
export const lookupDescr = (opts, id) => {
  const selected = (opts || []).find(t => t.id.toString() === id.toString());
  if (selected && selected.description) return selected.description;
  return "";
};
export const lookupId = (opts, descr) => {
  const selected = (opts || []).find(t => t.description === descr);
  if (selected && selected.id) return selected.id;
  return "";
};
export const lookupType = (opts, descr) =>
  (opts || []).find(t => t.description === descr);
