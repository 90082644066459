import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { safeNumber, formatNumber, SHORESIDE_CHECK } from "../../utils/utils";
import CheckNumberDropdown from "../common/CheckNumberDropdown";
import Dropdown from "../common/Dropdown";
import Modal from "../common/Modal";
import PeoplePickerPage from "../people/PeoplePickerPage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as lookupActions from "../../redux/actions/lookupActions";

const TimeSheetEarnings = ({ canEdit, ...props }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openModal = () => {
    setIsDialogOpen(true);
  };
  const closeModal = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    // console.log("In TimeSheetEarnings useEffect()");
    // console.log(props.earnTypes);
    props.actions.loadEarningTypes().catch(error => {
      alert("Loading earning types failed " + error);
    });
  }, [props.actions]);

  const earnTypesForCompany = useMemo(() => {
    // TODO: Deal with company IDs as an array and avoid string search non-uniqueness issues
    return props.earnTypes.filter(h =>
      (h.companyIds || "").includes(props.companyId)
    );
    /*
      .sort((a, b) => {
        // Sort first by the fewest companyIds; this will get the items only for this company first
        return a.companyIds.length === b.companyIds.length
          ? 0 // If not already sorted alphabetically, do that here
          : a.companyIds.length < b.companyIds.length
          ? -1
          : 1;
      });
    */
  }, [props.earnTypes, props.companyId]);

  const handleAddPeople = (people, earningTypes) => {
    if (props.onAddPeople) props.onAddPeople(people, earningTypes);
  };

  const handleFieldChange = (cardId, fieldName, value) => {
    if (canEdit && cardId && props.onChange)
      props.onChange("UPDATE_FIELD", cardId, fieldName, value);
  };

  const handleAmountFocus = evt => {
    evt.target.select();
    // const cardId = parseInt(evt.target.id);
    // const value = evt.target.value;
    // handleFieldChange(cardId, "amount", value.replace(/,/g, ""));
  };
  const handleAmountChange = (cardId, value) => {
    const moneyRegEx = /^(?:(?:\d?,?){0,8})(?:\.\d{0,4})?$/;
    if (moneyRegEx.test(value)) handleFieldChange(cardId, "amount", value);
  };

  const handleAmountBlur = evt => {
    if (canEdit) {
      const cardId = parseInt(evt.target.id);
      if (cardId && props.onChange)
        props.onChange("FORMAT_FIELD", cardId, "amount", evt.target.value);
      else console.log("handleAmountBlur is not properly configured");
    }
  };

  const handleRemove = cardId => {
    if (canEdit && props.onChange)
      props.onChange("REMOVE_CARD", cardId, "", "");
  };

  function getEarningsSummary() {
    if (props.data.length === 0) return [];
    const uniqueTypes = [
      ...new Set(
        props.data
          .map(r => r.earningType)
          .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1))
      )
    ];
    // console.log(uniqueTypes);
    const earningsSummary = uniqueTypes.map(et =>
      props.data
        .filter(r => r.earningType === et)
        .reduce(
          (typeSum, row) => ({
            ...typeSum,
            amount: typeSum.amount + safeNumber(row.amount)
          }),
          { earningType: et, amount: 0 }
        )
    );
    return earningsSummary;
  }

  // const earningsSort = (a, b) => {
  //   const aVal = a.employeeLastName + a.moranId + a.earningType;
  //   const bVal = b.employeeLastName + b.moranId + b.earningType;
  //   return aVal === bVal ? 0 : aVal > bVal ? 1 : -1;
  // };

  // const viewableCards = () => {
  //   // console.log("data", props.data);
  //   return (props.data || [])
  //     .filter(d => d.metadata && !d.metadata.deleted)
  //     .sort(earningsSort);
  // };

  return (
    <div className="timesheet-container">
      <table>
        <thead>
          <tr>
            <th className="col-one-half"></th>
            <th className="col-one-half" title="Emp ID">
              Emp ID
            </th>
            <th className="col-three" title="Crew Members">
              Crew Members
            </th>
            <th className="col-two" title="Default Position">
              Default Position
            </th>
            <th className="col-three" title="Earnings Type">
              Earnings Type
            </th>
            <th className="col-two" title="Amount ($)">
              Amount ($)
            </th>
            <th className="col-one" title="Check #">
              Check #
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((ec, i) => (
            <tr
              key={ec.earningsCardId}
              className={i % 2 === 1 ? "normal-bkg" : "alternate-bkg"}
            >
              <td>
                {canEdit && (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    title={`Remove: ${ec.employeeName} / ${ec.earningType}`}
                    onClick={() => handleRemove(ec.earningsCardId)}
                    className={canEdit ? "clickable" : ""}
                  />
                )}
              </td>
              <td title={ec.moranId}>{ec.moranId}</td>
              <td title={ec.employeeName}>{ec.employeeName}</td>
              <td title={ec.position}>{ec.position}</td>
              <td title={ec.earningType}>
                <Dropdown
                  types={earnTypesForCompany}
                  id={ec.earningsCardId}
                  selectedValue={ec.earningTypeId}
                  selectedText={ec.earningType}
                  onChange={handleFieldChange}
                  idColumn="earningTypeId"
                  textColumn="earningType"
                  canEdit={canEdit}
                />
              </td>
              <td title={ec.amount}>
                {canEdit ? (
                  <input
                    type="text"
                    id={ec.earningsCardId}
                    className="number dollars"
                    value={ec.amount}
                    onChange={e =>
                      handleAmountChange(ec.earningsCardId, e.target.value)
                    }
                    onBlur={handleAmountBlur}
                    onFocus={handleAmountFocus}
                    disabled={!canEdit}
                  />
                ) : (
                  <div className="number">{ec.amount}</div>
                )}
              </td>
              <td className="number" title={ec.checkNum}>
                {ec.checkNum === SHORESIDE_CHECK ? (
                  ec.checkNum
                ) : (
                  <CheckNumberDropdown
                    checkNum={ec.checkNum}
                    onChange={v =>
                      handleFieldChange(ec.earningsCardId, "checkNum", v)
                    }
                    canEdit={canEdit}
                  />
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan="7" className="timecard-no-border">
              {canEdit && <button onClick={openModal}>Add Rows</button>}
            </td>
          </tr>
          <tr>
            <td colSpan="6" className="timesheet-summary">
              Total Summary
            </td>
            <td className="timesheet-summary"></td>
          </tr>
          {getEarningsSummary().map((s, i) => (
            <tr key={i}>
              <td colSpan="5" className="timesheet-summary">
                {s.earningType}:
              </td>
              <td className="number">{formatNumber(s.amount, true, 4)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {canEdit && (
        <Modal isOpen={isDialogOpen} onClose={closeModal} title="People Picker">
          <PeoplePickerPage
            peopleIds={props.peopleIds || []}
            typesToAddLabel="Earnings Type"
            typesToAddOptions={(earnTypesForCompany || [])
              .map(t => t.description)
              .sort()}
            typesToAddDefaults={[]}
            onClose={closeModal}
            onAddPeople={handleAddPeople}
            cardCount={props.data.length}
            maxCount={100}
          />
        </Modal>
      )}
    </div>
  );
};

// Redux
function mapStateToProps(state) {
  return {
    earnTypes: state.earningTypes
  };
}

// const mapDispatchToProps = {
//   actions: lookupActions
// };
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(lookupActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetEarnings);
