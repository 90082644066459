import { payPeriod } from "../actions/actionTypes";
import initialState from "./initialState";

export default function exportReducer(state = initialState.dataExport, action) {
  switch (action.type) {
    case payPeriod.CREATE_EXPORT_PAYPERIOD_START:
    case payPeriod.EXPORT_PAYPERIOD_START:
      return { ...state, isFetching: true };
    case payPeriod.EXPORT_PAYPERIOD_SUCCESS:
      return {
        payPeriod: action.payPeriod,
        lastUpdated: new Date(),
        isFetching: false,
        data: action.data
      };
    case payPeriod.CREATE_EXPORT_PAYPERIOD_SUCCESS:
      return { ...initialState.dataExport };
    case payPeriod.CREATE_EXPORT_PAYPERIOD_FAILURE:
    case payPeriod.EXPORT_PAYPERIOD_FAILURE:
      return {
        ...initialState.dataExport,
        payPeriod: { id: action.payPeriodId },
        error: action.error
      };
    default:
      return state;
  }
}
