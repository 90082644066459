import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";
import HomePage from "./Components/home/HomePage";
import TimesheetPage from "./Components/timesheet/TimesheetPage";
import LoginPage from "./Components/login/LoginPage";
import Callback from "./auth/callback";
import AuthService from "./auth/authService";
import Authorized from "./auth/authorized";
import { ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApiVersion } from "./redux/actions/lookupActions";

const auth = new AuthService();
const handleAuthentication = (nextState, replace) => {
  if (nextState.location.search.includes("?code=")) {
    auth.handleAuthentication();
  }
};

function App({ getApiVersion }) {
  useEffect(() => {
    // console.log("App useEffect");
    getApiVersion();
  }, [getApiVersion]);

  return (
    <div className="App">
      <Switch>
        <Route
          path="/callback"
          render={props => {
            handleAuthentication(props);
            return <Callback {...props} />;
          }}
        />
        <Route
          exact
          path="/"
          render={props => <LoginPage auth={auth} {...props} />}
        />
        <Route
          path="/timeout"
          render={props => <LoginPage auth={auth} {...props} timeout={true} />}
        />

        <Route path="/home" component={Authorized(HomePage)} />
        <Route path="/timesheet/:tsid" component={Authorized(TimesheetPage)} />
      </Switch>
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        position={ToastPosition.TOP_CENTER}
      />
    </div>
  );
}

// Redux
const mapDispatchToProps = { getApiVersion };

export default connect(null, mapDispatchToProps)(App);
